import { SharedFunnelDatalayerEvent } from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';

export const CANCELLATION_BASE_URL = 'resiliation';

export const CANCELLATION_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Wallet_Resiliation',
  product_category: 'Resiliation',
  product_name: 'Wallet',
  funnel_complete: false,
};

export const CANCELLATION_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  GENERAL_INFO: Step;
  SENDER_ADDRESS: Step;
  INSURANCE_CHOICE: Step;
  RECEIVER_ADDRESS: Step;
  POLICY: Step;
  CANCELLATION_DATE: Step;
  LETTER_DATE: Step;
  SIGNATURE: Step;
  RECEIVER: Step;
  RECAP: Step;
  END: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 1],
    url: 'connexion',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 1],
    url: 'inscription',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Inscription',
    },
  },
  GENERAL_INFO: {
    step: [1, 1],
    url: 'informations-generales',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Informations générale',
    },
  },
  SENDER_ADDRESS: {
    step: [2, 1],
    url: 'adresse-expediteur',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Adresse expéditeur',
    },
  },
  INSURANCE_CHOICE: {
    step: [2, 1],
    url: 'assurance',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Assurance',
    },
  },
  RECEIVER_ADDRESS: {
    step: [2, 1],
    url: 'adresse-destinataire',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Adresse destinataire',
    },
  },
  POLICY: {
    step: [3, 1],
    url: 'police',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Police',
    },
  },
  CANCELLATION_DATE: {
    step: [3, 1],
    url: 'date-resiliation',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Date de résiliation',
    },
  },
  LETTER_DATE: {
    step: [3, 1],
    url: 'date-courrier',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Date du courrier',
    },
  },
  SIGNATURE: {
    step: [4, 1],
    url: 'signature',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Signatures',
    },
  },
  RECEIVER: {
    step: [5, 1],
    url: 'destinataire',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Destinataire',
    },
  },
  RECAP: {
    step: [5, 1],
    url: 'resume',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Récapitulatif',
    },
  },
  END: {
    step: [6],
    url: 'fin',
    datalayerEvent: {
      ...CANCELLATION_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Fin',
      funnel_complete: true,
    },
  },
};
