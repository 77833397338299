import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';

import { SelectElementMapper } from '../../_services/types/commons.type';
import { ContractToSignInfo } from '../../_services/types/contract-template.types';
import { ContractType } from '../../enums/contract-type.enum';
import { InsuranceTypeEnum } from '../../enums/insurance-type.enum';
import { TravelCoversEnum } from '../../enums/travel/travel-covers.enum';

export const TRAVEL_INSURANCE_BASE_URL = 'voyage';

export const TRAVEL_INSURANCE_FORM_BASE_URL = 'formulaire';

export const TRAVEL_INSURANCE_OFFER_BASE_URL = 'offre';

export const TRAVEL_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Voyage',
  product_category: 'Voyage',
  product_name: 'Market',
  funnel_complete: false,
};

export const TRAVEL_STEPS_BASE = `${TRAVEL_INSURANCE_BASE_URL}/${TRAVEL_INSURANCE_FORM_BASE_URL}`;

export const TRAVEL_STEPS: { POLICYHOLDER_COUNT: Step; START_DATE: Step; OFFER_SEARCH: Step } = {
  POLICYHOLDER_COUNT: {
    step: [1, 1],
    url: 'nombre-assure',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Nombre assurés',
    },
  },
  START_DATE: {
    step: [1, 1],
    url: 'date-debut',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Date début',
    },
  },
  OFFER_SEARCH: {
    step: [2, 1],
    url: 'offres',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Offres',
    },
  },
};

export const TRAVEL_OFFER_FORM_BASE = `${TRAVEL_INSURANCE_BASE_URL}/${TRAVEL_INSURANCE_OFFER_BASE_URL}`;

export const TRAVEL_OFFER_FORM_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  SUMMARY: Step;
  CONTRACT_SIGNATURE: Step;
  CANCELLATION: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 2],
    url: 'connexion',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 2],
    url: 'inscription',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Informations personnelles',
    },
  },
  SUMMARY: {
    step: [3, 1],
    url: 'recapitulatif',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Récapitulatif',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [4, 1],
    url: 'signature-contrat',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Signature',
    },
  },
  CANCELLATION: {
    step: [4, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: '(Option) Résiliation',
    },
  },
};

export const TRAVEL_COVERS: SelectElementMapper<TravelCoversEnum> = {
  [TravelCoversEnum.ANNULATION_FEE]: {
    value: TravelCoversEnum.ANNULATION_FEE,
    label: $localize`Frais d'annulation`,
  },
  [TravelCoversEnum.PREGNANCY]: {
    value: TravelCoversEnum.PREGNANCY,
    label: $localize`Grossesse`,
  },
  [TravelCoversEnum.RAPATRIATION]: {
    value: TravelCoversEnum.RAPATRIATION,
    label: $localize`Rapatriement`,
  },
  [TravelCoversEnum.RECHERCHE_AND_RESCUE]: {
    value: TravelCoversEnum.RECHERCHE_AND_RESCUE,
    label: $localize`Action de recherche`,
  },
  [TravelCoversEnum.HOSPITALIZATION_ABROAD]: {
    value: TravelCoversEnum.HOSPITALIZATION_ABROAD,
    label: $localize`Hospitalisation à l'étranger`,
  },
  [TravelCoversEnum.LEISURE_PROTECTION]: {
    value: TravelCoversEnum.LEISURE_PROTECTION,
    label: $localize`Protection loisirs`,
  },
  [TravelCoversEnum.FLIGHT_DELAY]: {
    value: TravelCoversEnum.FLIGHT_DELAY,
    label: $localize`Retards aériens`,
  },
  [TravelCoversEnum.LUGGAGE]: {
    value: TravelCoversEnum.LUGGAGE,
    label: $localize`Bagages`,
  },
  [TravelCoversEnum.CAT_AND_DOG]: {
    value: TravelCoversEnum.CAT_AND_DOG,
    label: $localize`Chient et chat`,
  },
  [TravelCoversEnum.MEDICAL_EXPENSE_WORLWIDE]: {
    value: TravelCoversEnum.MEDICAL_EXPENSE_WORLWIDE,
    label: $localize`Hospitalisation dans le monde entier`,
  },
  [TravelCoversEnum.FLIGHT_COMPANY_INSOLVENCY]: {
    value: TravelCoversEnum.FLIGHT_COMPANY_INSOLVENCY,
    label: $localize`Insolvabilité d'une compagnie aérienne`,
  },
  [TravelCoversEnum.NATURAL_DISASTER]: {
    value: TravelCoversEnum.NATURAL_DISASTER,
    label: $localize`Evénements naturels`,
  },
  [TravelCoversEnum.SECOND_EXAMINATION_COMPENSATION]: {
    value: TravelCoversEnum.SECOND_EXAMINATION_COMPENSATION,
    label: $localize`Indemnisation pour le second examen`,
  },
  [TravelCoversEnum.EUROPE_LEGAL_PROTECTION]: {
    value: TravelCoversEnum.EUROPE_LEGAL_PROTECTION,
    label: $localize`Protection juridique de voyage Europe`,
  },
  [TravelCoversEnum.WORLD_LEGAL_PROTECTION]: {
    value: TravelCoversEnum.WORLD_LEGAL_PROTECTION,
    label: $localize`Protection juridique de voyage Monde`,
  },
  [TravelCoversEnum.AIRCRAFT_ACCIDENT]: {
    value: TravelCoversEnum.AIRCRAFT_ACCIDENT,
    label: $localize`Accident d'aviation`,
  },
};

export const VOYAGE_END_URL = `${TRAVEL_INSURANCE_BASE_URL}/fin`;

export const TRAVEL_END_DATALAYER: FunnelDatalayerEvent = {
  ...TRAVEL_FUNNEL_EVENT_BASE,
  funnel_stage: 10,
  funnel_complete: true,
  stage_name: 'Fin',
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 voyage',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.TRAVEL,
  },
  {
    name: 'Mandat gestion voyage',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.TRAVEL,
  },
];
