import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';
import {
  BuildingType,
  ConstructionType,
  CoverageType,
  getBuildingTypeLabel,
  getConstructionTypeLabel,
  getHeatingSystemLabel,
  getRoofTypeLabel,
  getSecurityMeasureLabel,
  HeatingSystem,
  RoofType,
  SecurityMeasure,
} from 'src/app/enums/building.enums';
import { AntennaIconComponent } from 'src/app/ui-kit/icons/antenna-icon.component';
import { AppartmentComplex2IconComponent } from 'src/app/ui-kit/icons/appatment-complex-2-icon.component';
import { BrokenGlassIconComponent } from 'src/app/ui-kit/icons/broken-glass-icon.component';
import { CartwheelIconComponent } from 'src/app/ui-kit/icons/cartwheel-icon.component';
import { CrossIconComponent } from 'src/app/ui-kit/icons/cross-icon.component';
import { EarthquakeIconComponent } from 'src/app/ui-kit/icons/earthquake-icon.component';
import { FireAlarmIconComponent } from 'src/app/ui-kit/icons/fire-alarm-icon.component';
import { FireIconComponent } from 'src/app/ui-kit/icons/fire-icon.component';
import { FlatHouseIconComponent } from 'src/app/ui-kit/icons/house-flat-icon.component';
import { MinimalistHouseIconComponent } from 'src/app/ui-kit/icons/house-minimalist-icon.component';
import { SimpleHouseIconComponent } from 'src/app/ui-kit/icons/house-simple-icon.component';
import { ParkingIconComponent } from 'src/app/ui-kit/icons/parking-icon.component';
import { PipeIconComponent } from 'src/app/ui-kit/icons/pipe-icon.component';
import { OpenSquareIconComponent } from 'src/app/ui-kit/icons/square-open-icon.component';
import { ThiefIconComponent } from 'src/app/ui-kit/icons/thief-icon.component';
import { VillaIconComponent } from 'src/app/ui-kit/icons/villa-icon.component';

import { SelectElementMapper } from '../../_services/types/commons.type';
import { ContractToSignInfo } from '../../_services/types/contract-template.types';
import { ContractType } from '../../enums/contract-type.enum';
import { InsuranceTypeEnum } from '../../enums/insurance-type.enum';

export const BUILDING_INSURANCE_BASE = 'batiment';
export const BUILDING_INSURANCE_INPUT = 'formulaire';
export const BUILDING_INSURANCE_OFFER = 'offre';
export const BUILDING_INSURANCE_END = 'fin';
export const BUILDING_INSURANCE_END_URL = `${BUILDING_INSURANCE_BASE}/fin`;

export const BUILDING_INPUT_FORM_BASE = `${BUILDING_INSURANCE_BASE}/${BUILDING_INSURANCE_INPUT}/`;

export const BUILDING_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Batiment',
  product_category: 'Batiment',
  product_name: 'Market',
  funnel_complete: false,
};

export const BUILDING_INSURANCE_END_EVENT: FunnelDatalayerEvent = {
  ...BUILDING_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 21,
  stage_name: 'Fin',
};

export const BUILDING_INPUT_FORM_STEPS: {
  BIRTHDATE: Step;
  ROOM_COUNT: Step;
  BUILDING_TYPE: Step;
  COMPLEMENTARY_INFORMATION: Step;
  CONSTRUCTION_DATE: Step;
  SECURITY: Step;
  ADDRESS: Step;
  HEATING: Step;
  CONSTRUCTION_TYPE: Step;
  ROOF: Step;
  PRESTATIONS: Step;
  CIVIL_RESPONSABILITY: Step;
  DEDUCTIBLE: Step;
  OFFER_SEARCH: Step;
} = {
  BIRTHDATE: {
    step: [1, 1],
    url: 'date-naissance',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Date de naissance',
    },
  },
  ROOM_COUNT: {
    step: [2, 1],
    url: 'pieces',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Pièces',
    },
  },
  BUILDING_TYPE: {
    step: [2, 1],
    url: 'type-logement',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Type de logement',
    },
  },
  COMPLEMENTARY_INFORMATION: {
    step: [2, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Informations complémentaires',
    },
  },
  CONSTRUCTION_DATE: {
    step: [2, 1],
    url: 'construction',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Date de construction',
    },
  },
  SECURITY: {
    step: [2, 1],
    url: 'securite',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Sécurité',
    },
  },
  ADDRESS: {
    step: [2, 1],
    url: 'adresse',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Adresse',
    },
  },
  HEATING: {
    step: [2, 1],
    url: 'chauffage',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Chauffage',
    },
  },
  CONSTRUCTION_TYPE: {
    step: [2, 1],
    url: 'genre',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Type de construction',
    },
  },
  ROOF: {
    step: [2, 1],
    url: 'toit',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Toit',
    },
  },
  PRESTATIONS: {
    step: [3, 1],
    url: 'couvertures',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Couvertures',
    },
  },
  CIVIL_RESPONSABILITY: {
    step: [4, 1],
    url: 'responsabilite-civile',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Responsabilité civile',
    },
  },
  DEDUCTIBLE: {
    step: [4, 1],
    url: 'franchise',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Franchise',
    },
  },
  OFFER_SEARCH: {
    step: [5, 1],
    url: 'offres',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 14,
      stage_name: 'Offres',
    },
  },
};

export const BUILDING_OFFER_FORM_BASE = `${BUILDING_INSURANCE_BASE}/${BUILDING_INSURANCE_OFFER}/`;

export const BUILDING_OFFER_FORM_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  CONTRACT_SIGNATURE: Step;
  QUESTIONNARY: Step;
  CANCELLATION: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 1],
    url: 'connexion',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 16,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 1],
    url: 'inscription',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 16,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 17,
      stage_name: 'Informations personnelles',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [3, 1],
    url: 'signature-contrat',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 18,
      stage_name: 'Signatures',
    },
  },
  QUESTIONNARY: {
    step: [3, 1],
    url: 'questionnaire',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 19,
      stage_name: 'Questionnaire',
    },
  },
  CANCELLATION: {
    step: [3, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 20,
      stage_name: '(Option) Résiliation',
    },
  },
};

export const BUILDING_TYPES: SelectElementMapper<BuildingType> = {
  [BuildingType.ATTACHED_HOUSE]: {
    value: BuildingType.ATTACHED_HOUSE,
    label: getBuildingTypeLabel(BuildingType.ATTACHED_HOUSE),
    iconComponent: SimpleHouseIconComponent,
  },
  [BuildingType.FAMILY_HOUSE]: {
    value: BuildingType.FAMILY_HOUSE,
    label: getBuildingTypeLabel(BuildingType.FAMILY_HOUSE),
    iconComponent: MinimalistHouseIconComponent,
  },
  [BuildingType.SECONDARY_RESIDENCE]: {
    value: BuildingType.SECONDARY_RESIDENCE,
    label: getBuildingTypeLabel(BuildingType.SECONDARY_RESIDENCE),
    iconComponent: VillaIconComponent,
  },
  [BuildingType.UNDERGROUND_GARAGE]: {
    value: BuildingType.UNDERGROUND_GARAGE,
    label: getBuildingTypeLabel(BuildingType.UNDERGROUND_GARAGE),
    iconComponent: ParkingIconComponent,
  },
  [BuildingType.APPARTMENT_COMPLEX]: {
    value: BuildingType.APPARTMENT_COMPLEX,
    label: getBuildingTypeLabel(BuildingType.APPARTMENT_COMPLEX),
    iconComponent: AppartmentComplex2IconComponent,
  },
  [BuildingType.OTHER]: {
    value: BuildingType.OTHER,
    label: getBuildingTypeLabel(BuildingType.OTHER),
  },
};

export const SECURITY_MEASURES: SelectElementMapper<SecurityMeasure> = {
  [SecurityMeasure.ALARM]: {
    value: SecurityMeasure.ALARM,
    label: getSecurityMeasureLabel(SecurityMeasure.ALARM),
    iconComponent: FireAlarmIconComponent,
  },
  [SecurityMeasure.NONE]: {
    value: SecurityMeasure.NONE,
    label: getSecurityMeasureLabel(SecurityMeasure.NONE),
    iconComponent: CrossIconComponent,
  },
};

export const HEATING_SYSTEMS: SelectElementMapper<HeatingSystem> = {
  [HeatingSystem.FLOOR_HEATING]: {
    value: HeatingSystem.FLOOR_HEATING,
    label: getHeatingSystemLabel(HeatingSystem.FLOOR_HEATING),
  },
  [HeatingSystem.ROOF_HEATING]: {
    value: HeatingSystem.ROOF_HEATING,
    label: getHeatingSystemLabel(HeatingSystem.ROOF_HEATING),
  },
  [HeatingSystem.FLOOR_ROOF_HEATING]: {
    value: HeatingSystem.FLOOR_ROOF_HEATING,
    label: getHeatingSystemLabel(HeatingSystem.FLOOR_ROOF_HEATING),
  },
  [HeatingSystem.OTHER]: {
    value: HeatingSystem.OTHER,
    label: getHeatingSystemLabel(HeatingSystem.OTHER),
  },
};

export const CONSTRUCTION_TYPES: SelectElementMapper<ConstructionType> = {
  [ConstructionType.MASSIVE]: {
    value: ConstructionType.MASSIVE,
    label: getConstructionTypeLabel(ConstructionType.MASSIVE),
    info: $localize`Généralement, il s'agit des constructions en béton (par exemple une maison à étages ou un immeuble)`,
    iconComponent: OpenSquareIconComponent,
  },
  [ConstructionType.NOT_MASSIVE]: {
    value: ConstructionType.NOT_MASSIVE,
    label: getConstructionTypeLabel(ConstructionType.NOT_MASSIVE),
    info: $localize`Généralement, il s'agit de construction en bois (par exemple un chalet)`,
    iconComponent: CrossIconComponent,
  },
};

export const ROOF_TYPES: SelectElementMapper<RoofType> = {
  [RoofType.FLAT]: {
    value: RoofType.FLAT,
    label: getRoofTypeLabel(RoofType.FLAT),
    iconComponent: FlatHouseIconComponent,
  },
  [RoofType.NOT_FLAT]: {
    value: RoofType.NOT_FLAT,
    label: getRoofTypeLabel(RoofType.NOT_FLAT),
    iconComponent: SimpleHouseIconComponent,
  },
};

export const COVERAGES: SelectElementMapper<CoverageType> = {
  [CoverageType.FIRE_OR_DISASTERS]: {
    value: CoverageType.FIRE_OR_DISASTERS,
    label: $localize`Incendie / événements naturels`,
    iconComponent: FireIconComponent,
    info: $localize` elle couvre les 10 éléments suivants : 
    incendie 
    chutes de pierre 
    hautes eaux
    avalanche 
    pluie 
    inondations
    tempêtes 
    éboulement 
    grêle 
    glissement de terrain`,
  },
  [CoverageType.THEFT]: {
    value: CoverageType.THEFT,
    label: $localize`Vol avec effraction`,
    iconComponent: ThiefIconComponent,
  },
  [CoverageType.WATER_DAMAGE]: {
    value: CoverageType.WATER_DAMAGE,
    label: $localize`Dégâts d'eau`,
    iconComponent: PipeIconComponent,
    info: $localize`Cela englobe tout ce qui concerne les conduites d'eau et les canalisations`,
  },
  [CoverageType.GLASS_BREAKAGE]: {
    value: CoverageType.GLASS_BREAKAGE,
    label: $localize`Bris de glace`,
    iconComponent: BrokenGlassIconComponent,
    info: $localize`Cela englobe tout ce qui est vitrage du bâtiment`,
  },
  [CoverageType.EARTHQUAKE]: {
    value: CoverageType.EARTHQUAKE,
    label: $localize`Tremblement de terre`,
    iconComponent: EarthquakeIconComponent,
  },
  [CoverageType.TECHNICAL_SETUPS]: {
    value: CoverageType.TECHNICAL_SETUPS,
    label: $localize`Installations techniques`,
    iconComponent: AntennaIconComponent,
    info: $localize`englobe les éléments techniques qui sont rattachés au bâtiment (par exemple la ventilation, la climatisation, les panneaux solaires ou la pompe à chaleur)`,
  },
  [CoverageType.EXTERIOR]: {
    value: CoverageType.EXTERIOR,
    label: $localize`Aménagements extérieurs`,
    iconComponent: CartwheelIconComponent,
    info: $localize`Cela englobe le jardin, les buissons, les cabanes extérieurs, mur de soutènement)`,
  },
};

export const CIVIL_RESPONSABILITY_VALUES = [2000000, 3000000, 5000000, 10000000];
export const CIVIL_RESPONSABILITY_OPTIONS = ['2,000,000', '3,000,000', '5,000,000', '10,000,000'];

export const DEDUCTIBLE_VALUES = [0, 200, 500];

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 bâtiment',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.BUILDING,
  },
  {
    name: 'Mandat gestion bâtiment',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.BUILDING,
  },
];
