import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular-ivy';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-alt-snotify';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UiKitModule } from './ui-kit/ui-kit.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ToastrModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false, // environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgbAccordionModule,
    NgxIntlTelInputModule,
    SnotifyModule,
    UiKitModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    DatePipe,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}
