import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-pencil-line-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 28.0001H17.3333M3.33328 28.6667L10.7323 25.821C11.2056 25.6389 11.4422 25.5479 11.6636 25.4291C11.8602 25.3235 12.0477 25.2017 12.224 25.0649C12.4225 24.9109 12.6018 24.7316 12.9603 24.373L28 9.33341C29.4727 7.86065 29.4727 5.47284 28 4.00008C26.5272 2.52732 24.1394 2.52732 22.6667 4.00007L7.62699 19.0397C7.26845 19.3982 7.08918 19.5775 6.93514 19.776C6.79831 19.9523 6.67648 20.1398 6.57093 20.3365C6.45209 20.5578 6.36108 20.7945 6.17906 21.2677L3.33328 28.6667ZM3.33328 28.6667L6.07744 21.532C6.27381 21.0215 6.372 20.7662 6.5404 20.6493C6.68758 20.5471 6.86969 20.5084 7.04568 20.542C7.24706 20.5805 7.44046 20.7739 7.82726 21.1607L10.8394 24.1728C11.2262 24.5596 11.4196 24.753 11.458 24.9544C11.4916 25.1304 11.453 25.3125 11.3508 25.4597C11.2339 25.6281 10.9786 25.7263 10.468 25.9226L3.33328 28.6667Z"
        [attr.stroke]="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class PencilLineIconComponent extends BaseIconComponent {}
