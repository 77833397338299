import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';

import { SelectElementMapper } from '../../_services/types/commons.type';
import { ContractToSignInfo } from '../../_services/types/contract-template.types';
import { ContractType } from '../../enums/contract-type.enum';
import { InsuranceTypeEnum } from '../../enums/insurance-type.enum';
import {
  getVehiclePrestationLabel,
  VehiclePrestationsEnum,
} from '../../enums/vehicle/vehicle-prestations.enum';
import { CarDrivingIconComponent } from '../../ui-kit/icons/car-driving-icon.component';
import { ForbiddenIconComponent } from '../../ui-kit/icons/forbidden-icon.component';
import { GarageIconComponent } from '../../ui-kit/icons/garage-icon.component';
import { HeadLightIconComponent } from '../../ui-kit/icons/headlight-icon.component';
import { ParkingIconComponent } from '../../ui-kit/icons/parking-icon.component';
import { RepairIconComponent } from '../../ui-kit/icons/repair-icon.component';
import { ShieldIconComponent } from '../../ui-kit/icons/shield-icon.component';
import { TireIconComponent } from '../../ui-kit/icons/tire-icon.component';
import { WalletIconComponent } from '../../ui-kit/icons/wallet-icon.component';

export const VEHICLE_BASE_URL = 'vehicule';

export const VEHICLE_FORM_BASE_URL = 'formulaire';

export const VEHICLE_OFFER_BASE_URL = 'offre';

export const VEHICLE_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Vehicule',
  product_category: 'Vehicule',
  product_name: 'Market',
  funnel_complete: false,
};

export const VEHICLE_STEPS_BASE = `${VEHICLE_BASE_URL}/${VEHICLE_FORM_BASE_URL}`;

export const VEHICLE_STEPS: {
  OBJECT: Step;
  INPUT_TYPE: Step;
  FIRST_REGISTRATION_DATE: Step;
  BRAND: Step;
  MODEL: Step;
  DISTANCE: Step;
  MAIN_USE: Step;
  PARKING_AREA: Step;
  POLICY_HOLDER: Step;
  LEASING: Step;
  START_DATE: Step;
  PRESTATIONS: Step;
  OFFER_SEARCH: Step;
} = {
  OBJECT: {
    step: [1, 1],
    url: 'objet',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Objet',
    },
  },
  INPUT_TYPE: {
    step: [1, 2],
    url: 'type-selection',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Type de voiture',
    },
  },
  FIRST_REGISTRATION_DATE: {
    step: [1, 3],
    url: 'date-immatriculation',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: "Date d'immatriculation",
    },
  },
  BRAND: {
    step: [1, 4],
    url: 'marque',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Marque',
    },
  },
  MODEL: {
    step: [1, 5],
    url: 'model',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Modèle',
    },
  },
  DISTANCE: {
    step: [1, 6],
    url: 'distance',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Distance',
    },
  },
  MAIN_USE: {
    step: [1, 7],
    url: 'utilisation-principale',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Utilisation principale',
    },
  },
  PARKING_AREA: {
    step: [1, 8],
    url: 'parking',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Stationnement',
    },
  },
  POLICY_HOLDER: {
    step: [2, 1],
    url: 'prenneur-assurance',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: "Preneur d'assurance",
    },
  },
  LEASING: {
    step: [3, 1],
    url: 'leasing',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Leasing',
    },
  },
  START_DATE: {
    step: [4, 1],
    url: 'date-debut',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Date de début',
    },
  },
  PRESTATIONS: {
    step: [4, 2],
    url: 'couvertures',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Couvertures',
    },
  },
  OFFER_SEARCH: {
    step: [5, 1],
    url: 'recherche-offres',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Offres',
    },
  },
};

export const VEHICLE_OFFER_BASE = `${VEHICLE_BASE_URL}/${VEHICLE_OFFER_BASE_URL}/`;

export const VEHICLE_OFFER_FORM_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  FORM: Step;
  SUMMARY: Step;
  CANCELLATION: Step;
  CONTRACT_SIGNATURE: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 14,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 2],
    url: 'connexion',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 2],
    url: 'inscription',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 16,
      stage_name: 'Informations personnelles',
    },
  },
  FORM: {
    step: [3, 1],
    url: 'formulaire',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 17,
      stage_name: 'Questionnaire',
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'recapitulatif',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 18,
      stage_name: 'Récapitulatif',
    },
  },
  CANCELLATION: {
    step: [4, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 20,
      stage_name: '(Option) Résiliation',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [5, 1],
    url: 'signature-contrat',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 19,
      stage_name: 'Signatures',
    },
  },
};

export const VEHICLE_INSURANCE_END_EVENT: FunnelDatalayerEvent = {
  ...VEHICLE_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 21,
  stage_name: 'Fin',
};

export const VEHICLE_END_URL = `${VEHICLE_BASE_URL}/fin`;

export const COLLISION_DEDUCTIBLE = [
  { value: 500, label: 'CHF 500' },
  { value: 1000, label: "CHF 1'000" },
  { value: 2000, label: "CHF 2'000" },
];

export const PARTIAL_COVER_DEDUCTIBLE = [
  { value: 0, label: 'CHF 0' },
  { value: 100, label: 'CHF 100' },
  { value: 200, label: 'CHF 200' },
  { value: 300, label: 'CHF 300' },
  { value: 500, label: 'CHF 500' },
];

export const VEHICLE_COVERS: SelectElementMapper<VehiclePrestationsEnum> = {
  [VehiclePrestationsEnum.FREE_GARAGE_CHOICE]: {
    value: VehiclePrestationsEnum.FREE_GARAGE_CHOICE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.FREE_GARAGE_CHOICE),
    iconComponent: GarageIconComponent,
  },
  [VehiclePrestationsEnum.REPLACEMENT_VEHICLE]: {
    value: VehiclePrestationsEnum.REPLACEMENT_VEHICLE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.REPLACEMENT_VEHICLE),
    iconComponent: CarDrivingIconComponent,
  },
  [VehiclePrestationsEnum.PERSONAL_BELONGINGS]: {
    value: VehiclePrestationsEnum.PERSONAL_BELONGINGS,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PERSONAL_BELONGINGS),
    iconComponent: WalletIconComponent,
  },
  [VehiclePrestationsEnum.PASSENGERS_PROTECTION]: {
    value: VehiclePrestationsEnum.PASSENGERS_PROTECTION,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PASSENGERS_PROTECTION),
    iconComponent: ShieldIconComponent,
  },
  [VehiclePrestationsEnum.GROSS_NEGLIGENCE]: {
    value: VehiclePrestationsEnum.GROSS_NEGLIGENCE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.GROSS_NEGLIGENCE),
    iconComponent: ForbiddenIconComponent,
  },
  [VehiclePrestationsEnum.TROUBESHOOTING]: {
    value: VehiclePrestationsEnum.TROUBESHOOTING,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.TROUBESHOOTING),
    iconComponent: RepairIconComponent,
  },
  [VehiclePrestationsEnum.GLASS_PROTECTION]: {
    value: VehiclePrestationsEnum.GLASS_PROTECTION,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.GLASS_PROTECTION),
    iconComponent: HeadLightIconComponent,
  },
  [VehiclePrestationsEnum.TIRES]: {
    value: VehiclePrestationsEnum.TIRES,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.TIRES),
    iconComponent: TireIconComponent,
  },
  [VehiclePrestationsEnum.PARKING_DAMAGE]: {
    value: VehiclePrestationsEnum.PARKING_DAMAGE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PARKING_DAMAGE),
    iconComponent: ParkingIconComponent,
  },
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 véhicule',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.MOTOR_VEHICLE,
  },
  {
    name: 'Mandat gestion véhicule',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.MOTOR_VEHICLE,
  },
];
