import {
  BASE_ARTICLE_45,
  BASE_MANAGEMENT_AGREEMENT,
} from 'src/app/_services/constants/base-contracts-info';
import { ContractToSignInfo } from 'src/app/_services/types/contract-template.types';
import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';
import { CentralisationInsurersEnum } from 'src/app/enums/centralisation-insurers.enum';
import { InsuranceTypeEnum } from 'src/app/enums/insurance-type.enum';
import { LinearStepperElement } from 'src/app/ui-kit/components/linear-stepper/linear-stepper.component';

export const CENTRALISATION_BASE_URL = 'centralisation';

export const CENTRALISATION_RECAP_URL = 'recap';

export const CENTRALISATION_FORM_URL = 'formulaire';

export const CENTRALISATION_END_URL = 'fin';

export const CENTRALISATION_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Wallet_Centralisation',
  product_category: 'Centralisation',
  product_name: 'Wallet',
  funnel_complete: false,
};

export const CENTRALISATION_END_EVENT: FunnelDatalayerEvent = {
  ...CENTRALISATION_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 10,
  stage_name: 'Fin',
};

export const CENTRALISATION_STEPS: {
  MEMBERS_COUNT: Step;
  MAIN_FORM: Step;
  FAMILY_MEMBERS: Step;
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  SUMMARY: Step;
  CONTRACT_SIGNATURE: Step;
} = {
  MEMBERS_COUNT: {
    step: [1],
    url: 'nombre-personnes',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Nombre de personnes',
    },
  },
  MAIN_FORM: {
    step: [2],
    url: 'assurances',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Assurances',
    },
  },
  FAMILY_MEMBERS: {
    step: [3, 3],
    url: 'membre-famille',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Membre de famille',
    },
  },
  ACCOUNT: {
    step: [2, 1],
    url: 'compte',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [2, 1],
    url: 'connexion',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [2, 1],
    url: 'inscription',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Informations personnelles',
    },
  },
  SUMMARY: {
    step: [4],
    url: 'recapitulatif',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Récapitulatif',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [5],
    url: 'signature-contrat',
    datalayerEvent: {
      ...CENTRALISATION_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Signatures',
    },
  },
};

export const CENTRALISATION_INSURERS: { [key: string]: string } = {
  [CentralisationInsurersEnum.AGRISANO]: $localize`Agrisano`,
  [CentralisationInsurersEnum.ALLIANZ]: $localize`Allianz`,
  [CentralisationInsurersEnum.ANIMALIA]: $localize`Animalia`,
  [CentralisationInsurersEnum.APPENZELLER]: $localize`Appenzeller`,
  [CentralisationInsurersEnum.AQUILANA]: $localize`Aquilana`,
  [CentralisationInsurersEnum.ASSISTA]: $localize`Assista Protection juridique SA`,
  [CentralisationInsurersEnum.ASSURA]: $localize`Assura SA`,
  [CentralisationInsurersEnum.ATUPRI]: $localize`Atupri`,
  [CentralisationInsurersEnum.AXA]: $localize`AXA`,
  [CentralisationInsurersEnum.AXA_ARAG]: $localize`AXA-ARAG`,
  [CentralisationInsurersEnum.BALOISE]: $localize`Baloise assurance`,
  [CentralisationInsurersEnum.CAP]: $localize`CAP`,
  [CentralisationInsurersEnum.CONCORDIA]: $localize`CONCORDIA`,
  [CentralisationInsurersEnum.COOP]: $localize`Coop`,
  [CentralisationInsurersEnum.CSS]: $localize`CSS assurance`,
  [CentralisationInsurersEnum.DEXTRA]: $localize`Dextra`,
  [CentralisationInsurersEnum.EGK]: $localize`EGK`,
  [CentralisationInsurersEnum.ELVIA]: $localize`Elvia`,
  [CentralisationInsurersEnum.EMILIA]: $localize`Emilia`,
  [CentralisationInsurersEnum.EMMENTAL]: $localize`Emmental`,
  [CentralisationInsurersEnum.EPONA]: $localize`EPONA`,
  [CentralisationInsurersEnum.EUROP_ASSISTANCE]: $localize`Europ Assistance`,
  [CentralisationInsurersEnum.FIRSTCAUTION]: $localize`Firstcaution`,
  [CentralisationInsurersEnum.FORTUNA]: $localize`Fortuna`,
  [CentralisationInsurersEnum.GENERALI]: $localize`Generali`,
  [CentralisationInsurersEnum.GENOSSENSCHAFT]: $localize`Genossenschaft`,
  [CentralisationInsurersEnum.GENOSSENSCHAFT_SLKK]: $localize`Genossenschaft SLKK`,
  [CentralisationInsurersEnum.GROUPE_MUTUEL]: $localize`Groupe Mutuel`,
  [CentralisationInsurersEnum.GVB]: $localize`GVB`,
  [CentralisationInsurersEnum.HELSANA]: $localize`Helsana`,
  [CentralisationInsurersEnum.HELVETIA]: $localize`Helvetia assurance`,
  [CentralisationInsurersEnum.IMPERIO]: $localize`Imperio`,
  [CentralisationInsurersEnum.INFRASSURE]: $localize`Infrassure`,
  [CentralisationInsurersEnum.INNOVA]: $localize`Innova`,
  [CentralisationInsurersEnum.KPT]: $localize`KPT`,
  [CentralisationInsurersEnum.LUZEMER_HINTERLAND]: $localize`Luzemer Hinterland`,
  [CentralisationInsurersEnum.K_TIPP]: $localize`K-Tipp`,
  [CentralisationInsurersEnum.LIMMAT]: $localize`Limmat`,
  [CentralisationInsurersEnum.OKK]: $localize`ÖKK`,
  [CentralisationInsurersEnum.ORION]: $localize`Orion`,
  [CentralisationInsurersEnum.PALLADIO]: $localize`Palladio`,
  [CentralisationInsurersEnum.PAX]: $localize`Pax`,
  [CentralisationInsurersEnum.PROTEKTA]: $localize`Protekta`,
  [CentralisationInsurersEnum.POST_FINANCES]: $localize`Post Finances`,
  [CentralisationInsurersEnum.RETRAITES_POPULAIRES]: $localize`Retraites Populaires`,
  [CentralisationInsurersEnum.RHENUSANA]: $localize`Rhenusana`,
  [CentralisationInsurersEnum.SANITAS]: $localize`Sanitas`,
  [CentralisationInsurersEnum.SWISSCAUTION]: $localize`SwissCaution`,
  [CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL]: $localize`Schweizerische Hagel`,
  [CentralisationInsurersEnum.LA_MOBILIERE]: $localize`La Mobilière`,
  [CentralisationInsurersEnum.SIMPEGO]: $localize`Simpego`,
  [CentralisationInsurersEnum.SMARTCAUTION]: $localize`SmartCaution`,
  [CentralisationInsurersEnum.SAVANALS]: $localize`Savanals`,
  [CentralisationInsurersEnum.SLLK]: $localize`SLLK`,
  [CentralisationInsurersEnum.STEFFISBURG]: $localize`Steffisburg`,
  [CentralisationInsurersEnum.SODALIS]: $localize`Sodalis`,
  [CentralisationInsurersEnum.STIFTUNG_KRANKENKASSE_WADENSWIL]: $localize`Stiftung Krankenkasse Wädenswil`,
  [CentralisationInsurersEnum.SUMISWALDER]: $localize`Sumiswalder`,
  [CentralisationInsurersEnum.SWICA]: $localize`Swica`,
  [CentralisationInsurersEnum.SWISS_LIFE]: $localize`Swiss Life AG`,
  [CentralisationInsurersEnum.SWISSGARANTA]: $localize`Swissgaranta`,
  [CentralisationInsurersEnum.SYMPANY]: $localize`Sympany`,
  [CentralisationInsurersEnum.SMILE]: $localize`Smile`,
  [CentralisationInsurersEnum.TAS_TCS]: $localize`TAS / TCS`,
  [CentralisationInsurersEnum.TSM]: $localize`TSM`,
  [CentralisationInsurersEnum.VAUDOISE]: $localize`Vaudoise`,
  [CentralisationInsurersEnum.VERSICHERUNG_DER_SCHWEIZER_ARZTE_GENOSSENCHAFT]: $localize`Versicherung der Schweizer Ärzte Genossenschaft`,
  [CentralisationInsurersEnum.VISANA]: $localize`Visana`,
  [CentralisationInsurersEnum.VITA_SURSELVA]: $localize`Vita Surselva`,
  [CentralisationInsurersEnum.VZ]: $localize`VZ`,
  [CentralisationInsurersEnum.YOUPLUS]: $localize`Youplus`,
  [CentralisationInsurersEnum.ZURICH_ASSURANCE]: $localize`Zurich Assurance`,
  [CentralisationInsurersEnum.WADENSWILL_KK]: $localize`Wadenswill KK`,
  [CentralisationInsurersEnum.WAU_MIAU]: $localize`Wau Miau`,
};

export const CENTRALISATION_INSURERS_BY_INSURANCE: { [key: string]: string[] } = {
  [InsuranceTypeEnum.HEALTH]: [
    CentralisationInsurersEnum.AGRISANO,
    CentralisationInsurersEnum.AQUILANA,
    CentralisationInsurersEnum.ASSURA,
    CentralisationInsurersEnum.ATUPRI,
    CentralisationInsurersEnum.CONCORDIA,
    CentralisationInsurersEnum.CSS,
    CentralisationInsurersEnum.EGK,
    CentralisationInsurersEnum.GENOSSENSCHAFT,
    CentralisationInsurersEnum.GENOSSENSCHAFT_SLKK,
    CentralisationInsurersEnum.GROUPE_MUTUEL,
    CentralisationInsurersEnum.HELSANA,
    CentralisationInsurersEnum.INNOVA,
    CentralisationInsurersEnum.KPT,
    CentralisationInsurersEnum.LUZEMER_HINTERLAND,
    CentralisationInsurersEnum.OKK,
    CentralisationInsurersEnum.RHENUSANA,
    CentralisationInsurersEnum.SANITAS,
    CentralisationInsurersEnum.SAVANALS,
    CentralisationInsurersEnum.SLLK,
    CentralisationInsurersEnum.STEFFISBURG,
    CentralisationInsurersEnum.SODALIS,
    CentralisationInsurersEnum.STIFTUNG_KRANKENKASSE_WADENSWIL,
    CentralisationInsurersEnum.SUMISWALDER,
    CentralisationInsurersEnum.SWICA,
    CentralisationInsurersEnum.SYMPANY,
    CentralisationInsurersEnum.VISANA,
    CentralisationInsurersEnum.VITA_SURSELVA,
    CentralisationInsurersEnum.WADENSWILL_KK,
  ],
  [InsuranceTypeEnum.THIRD_PILLAR]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.GROUPE_MUTUEL,
    CentralisationInsurersEnum.IMPERIO,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.PALLADIO,
    CentralisationInsurersEnum.PAX,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.POST_FINANCES,
    CentralisationInsurersEnum.RETRAITES_POPULAIRES,
    CentralisationInsurersEnum.SWISS_LIFE,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.VERSICHERUNG_DER_SCHWEIZER_ARZTE_GENOSSENCHAFT,
    CentralisationInsurersEnum.VZ,
    CentralisationInsurersEnum.YOUPLUS,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.BENEFITS_ACCOUNT]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.COMPLEMENTARY_HEALTH]: [
    CentralisationInsurersEnum.AGRISANO,
    CentralisationInsurersEnum.AQUILANA,
    CentralisationInsurersEnum.ASSURA,
    CentralisationInsurersEnum.ATUPRI,
    CentralisationInsurersEnum.CONCORDIA,
    CentralisationInsurersEnum.CSS,
    CentralisationInsurersEnum.EGK,
    CentralisationInsurersEnum.GENOSSENSCHAFT,
    CentralisationInsurersEnum.GENOSSENSCHAFT_SLKK,
    CentralisationInsurersEnum.GROUPE_MUTUEL,
    CentralisationInsurersEnum.HELSANA,
    CentralisationInsurersEnum.INNOVA,
    CentralisationInsurersEnum.KPT,
    CentralisationInsurersEnum.LUZEMER_HINTERLAND,
    CentralisationInsurersEnum.OKK,
    CentralisationInsurersEnum.RHENUSANA,
    CentralisationInsurersEnum.SANITAS,
    CentralisationInsurersEnum.SAVANALS,
    CentralisationInsurersEnum.SLLK,
    CentralisationInsurersEnum.STEFFISBURG,
    CentralisationInsurersEnum.SODALIS,
    CentralisationInsurersEnum.STIFTUNG_KRANKENKASSE_WADENSWIL,
    CentralisationInsurersEnum.SUMISWALDER,
    CentralisationInsurersEnum.SWICA,
    CentralisationInsurersEnum.WADENSWILL_KK,
  ],
  [InsuranceTypeEnum.RC_HOUSEHOLD]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.APPENZELLER,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.CSS,
    CentralisationInsurersEnum.ELVIA,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.GROUPE_MUTUEL,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.SIMPEGO,
    CentralisationInsurersEnum.SMILE,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.BUILDING]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.GVB,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.CONSTRUCTION]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.GVB,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.MOTOR_VEHICLE]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.SIMPEGO,
    CentralisationInsurersEnum.SMILE,
    CentralisationInsurersEnum.TAS_TCS,
    CentralisationInsurersEnum.TSM,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.TRAVEL]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.APPENZELLER,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.EUROP_ASSISTANCE,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.TAS_TCS,
    CentralisationInsurersEnum.TSM,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.LEGAL_PROTECTION]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.ASSISTA,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.AXA_ARAG,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.CAP,
    CentralisationInsurersEnum.COOP,
    CentralisationInsurersEnum.DEXTRA,
    CentralisationInsurersEnum.ELVIA,
    CentralisationInsurersEnum.EMILIA,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.FORTUNA,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.GROUPE_MUTUEL,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.K_TIPP,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.ORION,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.PROTEKTA,
    CentralisationInsurersEnum.TAS_TCS,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.HOUSING_RENTAL]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.FIRSTCAUTION,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.SMARTCAUTION,
    CentralisationInsurersEnum.SWISSCAUTION,
    CentralisationInsurersEnum.SWISSGARANTA,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.VALUABLE_OBJECTS]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
  ],
  [InsuranceTypeEnum.ANIMAL]: [
    CentralisationInsurersEnum.ALLIANZ,
    CentralisationInsurersEnum.ANIMALIA,
    CentralisationInsurersEnum.AXA,
    CentralisationInsurersEnum.BALOISE,
    CentralisationInsurersEnum.EMMENTAL,
    CentralisationInsurersEnum.EPONA,
    CentralisationInsurersEnum.GENERALI,
    CentralisationInsurersEnum.HELVETIA,
    CentralisationInsurersEnum.INFRASSURE,
    CentralisationInsurersEnum.LIMMAT,
    CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL,
    CentralisationInsurersEnum.LA_MOBILIERE,
    CentralisationInsurersEnum.VAUDOISE,
    CentralisationInsurersEnum.ZURICH_ASSURANCE,
    CentralisationInsurersEnum.WAU_MIAU,
  ],
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    ...BASE_ARTICLE_45,
    name: 'Article 45 global',
    insuranceType: InsuranceTypeEnum.CENTRALISATION,
  },
  {
    ...BASE_MANAGEMENT_AGREEMENT,
    name: 'Mandat gestion global',
    insuranceType: InsuranceTypeEnum.CENTRALISATION,
  },
];

export const STEPS_MAPPING = {
  insurances: $localize`Assurances à centraliser `,
};

export const BASE_PERSON_STEPS = Object.values(STEPS_MAPPING);

export const FIRST_STEP_TITLE = $localize`Personnes à centraliser`;

export const END_STEPS_MAPPING = {
  summary: $localize`Récapitulatif`,
  contractSignature: $localize`Signatures`,
};

export const END_SCREEN_TEXTS: LinearStepperElement[] = [
  {
    title: $localize`Nous y voilà, maintenant LTY se charge de contacter la/les compagnie(s) pour recevoir tes polices`,
  },
  {
    title: $localize`Nous intégrons ta/tes police(s) au Wallet digital`,
  },
  {
    title: $localize`Ta /tes polices(s) sont accessible(s) dans ton Wallet`,
  },
];
