import { SelectElementMapper } from '../_services/types/commons.type';
import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from '../_services/types/datalayer.types';
import { Step } from '../_services/types/step.type';
import { ClaimType } from '../enums/claim/claim-type.enum';
import {
  ClaimLegalDomain,
  getClaimLegalDomainLabel,
  getLegalDelayLabel,
  LegalDelay,
} from '../enums/claim/legal-claim.enums';
import { DamagedGood, getDamagedGoodLabel } from '../enums/claim/valuable-object.enums';
import {
  getVehicleDamageTargetLabel,
  VehicleDamageTarget,
} from '../enums/claim/vehicle-claim.enums';
import {
  ANIMAL_FUNNEL_EVENT_BASE,
  ANIMAL_INSURANCE_BASE,
} from '../insurance-flows/animal/constants';
import {
  BUILDING_FUNNEL_EVENT_BASE,
  BUILDING_INSURANCE_BASE,
} from '../insurance-flows/building/constants';
import {
  CONSTRUCTION_FUNNEL_EVENT_BASE,
  CONSTRUCTION_INSURANCE_BASE_URL,
} from '../insurance-flows/construction/constants';
import {
  HOUSEHOLD_FUNNEL_EVENT_BASE,
  HOUSEHOLD_INSURANCE_BASE_URL,
} from '../insurance-flows/household/household-constants';
import { LEGAL_PROTECTION_BASE_URL } from '../insurance-flows/legal-protection/legal-protection-constants';
import {
  TRAVEL_FUNNEL_EVENT_BASE,
  TRAVEL_INSURANCE_BASE_URL,
} from '../insurance-flows/travel/travel-constants';
import {
  VALUABLE_OBJECTS_BASE,
  VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
} from '../insurance-flows/valuable-objects/constants';
import {
  VEHICLE_BASE_URL,
  VEHICLE_FUNNEL_EVENT_BASE,
} from '../insurance-flows/vehicle/vehicle-constants';
import { CameraIconComponent } from '../ui-kit/icons/camera-icon.component';
import { EarIconComponent } from '../ui-kit/icons/ear-icon.component';
import { FurcoatIconComponent } from '../ui-kit/icons/furcoat-icon.component';
import { GunIconComponent } from '../ui-kit/icons/gun-icon.component';
import { JewelryIconComponent } from '../ui-kit/icons/jewelry-icon.component';
import { PaintingIconComponent } from '../ui-kit/icons/painting-icon.component';
import { PianoIconComponent } from '../ui-kit/icons/piano-icon.component';

export const CLAIM_BASE_URL = 'sinistre';
export const CLAIM_FORM_BASE_URL = 'formulaire';

export const CLAIM_FORM_FULL_URL = `${CLAIM_BASE_URL}/${CLAIM_FORM_BASE_URL}`;

export const CLAIM_GENERAL_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'General',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_STEPS: {
  START: Step;
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  DOCUMENT_UPLOAD: Step;
  ID_UPLOAD: Step;
  INSURANCE_CHOICE: Step;
  END: Step;
} = {
  START: {
    url: 'recap',
    step: [0, 0],
    datalayerEvent: {
      ...CLAIM_GENERAL_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Récap',
    },
  },
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...CLAIM_GENERAL_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 1],
    url: 'connexion',
    datalayerEvent: {
      ...CLAIM_GENERAL_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 1],
    url: 'inscription',
    datalayerEvent: {
      ...CLAIM_GENERAL_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Inscription',
    },
  },
  DOCUMENT_UPLOAD: {
    step: [1, 1],
    url: 'documents',
    datalayerEvent: {
      ...CLAIM_GENERAL_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Documents',
    },
  },
  ID_UPLOAD: {
    step: [1, 1],
    url: 'identite',
    datalayerEvent: {
      ...CLAIM_GENERAL_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: "Pièce d'identité",
    },
  },
  INSURANCE_CHOICE: {
    step: [2, 1],
    url: 'choix-assurance',
    datalayerEvent: {
      ...CLAIM_GENERAL_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: "Choix d'assurance",
    },
  },
  END: {
    url: 'fin',
    step: [0, 0],
    datalayerEvent: {
      ...CLAIM_GENERAL_FUNNEL_EVENT_BASE,
      funnel_stage: -1,
      stage_name: 'Fin',
      funnel_complete: true,
    },
  },
};

export const CLAIM_ANIMAL_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'Animal',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_ANIMAL_STEPS: {
  CAUSE: Step;
  SITUATION: Step;
  REIMBURSEMENT_INFO: Step;
  ADDITIONAL_INFO: Step;
  SUMMARY: Step;
} = {
  CAUSE: {
    step: [3, 1],
    url: 'cause',
    datalayerEvent: {
      ...CLAIM_ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Cause',
      funnel_complete: false,
    },
  },
  SITUATION: {
    step: [3, 1],
    url: 'situation',
    datalayerEvent: {
      ...CLAIM_ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Situation',
      funnel_complete: false,
    },
  },
  REIMBURSEMENT_INFO: {
    step: [3, 1],
    url: 'remboursement',
    datalayerEvent: {
      ...CLAIM_ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Remboursement',
      funnel_complete: false,
    },
  },
  ADDITIONAL_INFO: {
    step: [3, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...CLAIM_ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Information complémentaire',
      funnel_complete: false,
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'resume',
    datalayerEvent: {
      ...CLAIM_ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Récapitulatif',
      funnel_complete: false,
    },
  },
};

export const CLAIM_VEHICLE_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'Vehicule',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_VEHICLE_STEPS: {
  CAUSE: Step;
  OATH: Step;
  CIRCUMSTANCES: Step;
  DAMAGE_TARGET: Step;
  GARAGE_VISIT: Step;
  CLAIM_DETAILS: Step;
  ADDITIONAL_INFO: Step;
  PROOF: Step;
  SUMMARY: Step;
} = {
  CAUSE: {
    step: [3, 1],
    url: 'cause',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Cause',
      funnel_complete: false,
    },
  },
  OATH: {
    step: [3, 1],
    url: 'serment',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Serment',
      funnel_complete: false,
    },
  },
  CIRCUMSTANCES: {
    step: [3, 1],
    url: 'circonstances',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Circonstances',
      funnel_complete: false,
    },
  },
  DAMAGE_TARGET: {
    step: [3, 1],
    url: 'dommage',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Dommage',
      funnel_complete: false,
    },
  },
  GARAGE_VISIT: {
    step: [3, 1],
    url: 'garage',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Garage',
      funnel_complete: false,
    },
  },
  CLAIM_DETAILS: {
    step: [3, 1],
    url: 'details',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Détails',
      funnel_complete: false,
    },
  },
  ADDITIONAL_INFO: {
    step: [3, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Informations complémentaires',
      funnel_complete: false,
    },
  },
  PROOF: {
    step: [3, 1],
    url: 'preuves',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Preuves',
      funnel_complete: false,
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'resume',
    datalayerEvent: {
      ...CLAIM_VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 14,
      stage_name: 'Récapitulatif',
      funnel_complete: false,
    },
  },
};

export const CLAIM_BUILDING_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'Batiment',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_BUILDING_STEPS: {
  CAUSE: Step;
  OATH: Step;
  CIRCUMSTANCES: Step;
  DAMAGE_TARGET: Step;
  CLAIM_DETAILS: Step;
  ADDITIONAL_INFO: Step;
  PROOF: Step;
  SUMMARY: Step;
} = {
  CAUSE: {
    step: [3, 1],
    url: 'cause',
    datalayerEvent: {
      ...CLAIM_BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Cause',
      funnel_complete: false,
    },
  },
  OATH: {
    step: [3, 1],
    url: 'serment',
    datalayerEvent: {
      ...CLAIM_BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Serment',
      funnel_complete: false,
    },
  },
  CIRCUMSTANCES: {
    step: [3, 1],
    url: 'circonstances',
    datalayerEvent: {
      ...CLAIM_BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Circonstances',
      funnel_complete: false,
    },
  },
  DAMAGE_TARGET: {
    step: [3, 1],
    url: 'dommage',
    datalayerEvent: {
      ...CLAIM_BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Dommage',
      funnel_complete: false,
    },
  },
  CLAIM_DETAILS: {
    step: [3, 1],
    url: 'details',
    datalayerEvent: {
      ...CLAIM_BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Détails',
      funnel_complete: false,
    },
  },
  ADDITIONAL_INFO: {
    step: [3, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...CLAIM_BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Informations complémentaires',
      funnel_complete: false,
    },
  },
  PROOF: {
    step: [3, 1],
    url: 'preuves',
    datalayerEvent: {
      ...CLAIM_BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Preuves',
      funnel_complete: false,
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'resume',
    datalayerEvent: {
      ...CLAIM_BUILDING_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Récapitulatif',
      funnel_complete: false,
    },
  },
};

export const CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'Rc Menage',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_HOUSEHOLD_STEPS: {
  CAUSE: Step;
  OATH: Step;
  CIRCUMSTANCES: Step;
  DAMAGE_TARGET: Step;
  CLAIM_DETAILS: Step;
  ADDITIONAL_INFO: Step;
  PROOF: Step;
  SUMMARY: Step;
} = {
  CAUSE: {
    step: [3, 1],
    url: 'cause',
    datalayerEvent: {
      ...CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Cause',
      funnel_complete: false,
    },
  },
  OATH: {
    step: [3, 1],
    url: 'serment',
    datalayerEvent: {
      ...CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Serment',
      funnel_complete: false,
    },
  },
  CIRCUMSTANCES: {
    step: [3, 1],
    url: 'circonstances',
    datalayerEvent: {
      ...CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Circonstances',
      funnel_complete: false,
    },
  },
  DAMAGE_TARGET: {
    step: [3, 1],
    url: 'dommage',
    datalayerEvent: {
      ...CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Dommage',
      funnel_complete: false,
    },
  },
  CLAIM_DETAILS: {
    step: [3, 1],
    url: 'details',
    datalayerEvent: {
      ...CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Details',
      funnel_complete: false,
    },
  },
  ADDITIONAL_INFO: {
    step: [3, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Informations complémentaires',
      funnel_complete: false,
    },
  },
  PROOF: {
    step: [3, 1],
    url: 'preuves',
    datalayerEvent: {
      ...CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Preuves',
      funnel_complete: false,
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'resume',
    datalayerEvent: {
      ...CLAIM_HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Récapitulatif',
      funnel_complete: false,
    },
  },
};

export const CLAIM_VALUABLE_OBJECTS_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'Objets Valeur',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_VALUABLE_OBJECTS_STEPS = {
  CAUSE: {
    step: [3, 1],
    url: 'cause',
    datalayerEvent: {
      ...CLAIM_VALUABLE_OBJECTS_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Cause',
      funnel_complete: false,
    },
  },
  OATH: {
    step: [3, 1],
    url: 'serment',
    datalayerEvent: {
      ...CLAIM_VALUABLE_OBJECTS_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Serment',
      funnel_complete: false,
    },
  },
  CIRCUMSTANCES: {
    step: [3, 1],
    url: 'circonstances',
    datalayerEvent: {
      ...CLAIM_VALUABLE_OBJECTS_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Circonstances',
      funnel_complete: false,
    },
  },
  DAMAGE_TARGET: {
    step: [3, 1],
    url: 'dommage',
    datalayerEvent: {
      ...CLAIM_VALUABLE_OBJECTS_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Dommage',
      funnel_complete: false,
    },
  },
  CLAIM_DETAILS: {
    step: [3, 1],
    url: 'details',
    datalayerEvent: {
      ...CLAIM_VALUABLE_OBJECTS_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Détails',
      funnel_complete: false,
    },
  },
  ADDITIONAL_INFO: {
    step: [3, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...CLAIM_VALUABLE_OBJECTS_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Informations complémentaires',
      funnel_complete: false,
    },
  },
  PROOF: {
    step: [3, 1],
    url: 'preuves',
    datalayerEvent: {
      ...CLAIM_VALUABLE_OBJECTS_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Preuves',
      funnel_complete: false,
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'resume',
    datalayerEvent: {
      ...CLAIM_VALUABLE_OBJECTS_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Récapitulatifs',
      funnel_complete: false,
    },
  },
};

export const CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'Construction',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_CONSTRUCTION_STEPS = {
  CAUSE: {
    step: [3, 1],
    url: 'cause',
    datalayerEvent: {
      ...CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Cause',
      funnel_complete: false,
    },
  },
  OATH: {
    step: [3, 1],
    url: 'serment',
    datalayerEvent: {
      ...CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Serment',
      funnel_complete: false,
    },
  },
  CIRCUMSTANCES: {
    step: [3, 1],
    url: 'circonstances',
    datalayerEvent: {
      ...CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Circonstances',
      funnel_complete: false,
    },
  },
  DAMAGE_TARGET: {
    step: [3, 1],
    url: 'dommage',
    datalayerEvent: {
      ...CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Dommage',
      funnel_complete: false,
    },
  },
  CLAIM_DETAILS: {
    step: [3, 1],
    url: 'details',
    datalayerEvent: {
      ...CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Détails',
      funnel_complete: false,
    },
  },
  ADDITIONAL_INFO: {
    step: [3, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Informations complémentaires',
      funnel_complete: false,
    },
  },
  PROOF: {
    step: [3, 1],
    url: 'preuves',
    datalayerEvent: {
      ...CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Preuves',
      funnel_complete: false,
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'resume',
    datalayerEvent: {
      ...CLAIM_CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Récapitulatifs',
      funnel_complete: false,
    },
  },
};

export const CLAIM_TRAVEL_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'Voyage',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_TRAVEL_STEPS = {
  OCCURENCE: {
    step: [3, 1],
    url: 'moment',
    datalayerEvent: {
      ...CLAIM_TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Moment',
      funnel_complete: false,
    },
  },
  OATH: {
    step: [3, 1],
    url: 'serment',
    datalayerEvent: {
      ...CLAIM_TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Serment',
      funnel_complete: false,
    },
  },
  CIRCUMSTANCES: {
    step: [3, 1],
    url: 'circonstances',
    datalayerEvent: {
      ...CLAIM_TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Circonstances',
      funnel_complete: false,
    },
  },
  DAMAGE_TARGET: {
    step: [3, 1],
    url: 'dommage',
    datalayerEvent: {
      ...CLAIM_TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Dommage',
      funnel_complete: false,
    },
  },
  CLAIM_DETAILS: {
    step: [3, 1],
    url: 'details',
    datalayerEvent: {
      ...CLAIM_TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Détails',
      funnel_complete: false,
    },
  },
  ADDITIONAL_INFO: {
    step: [3, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...CLAIM_TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Informations complémentaires',
      funnel_complete: false,
    },
  },
  PROOF: {
    step: [3, 1],
    url: 'preuves',
    datalayerEvent: {
      ...CLAIM_TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Preuves',
      funnel_complete: false,
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'resume',
    datalayerEvent: {
      ...CLAIM_TRAVEL_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Résumé',
      funnel_complete: false,
    },
  },
};

export const CLAIM_LEGAL_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  product_category: 'Claim',
  product_name: 'Protection Juridique',
  funnel_complete: false,
  get event() {
    return `${this.product_category}_${this.product_name}`;
  },
};

export const CLAIM_LEGAL_STEPS = {
  CASE: {
    step: [3, 1],
    url: 'cas',
    datalayerEvent: {
      ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Cas',
      funnel_complete: false,
    },
  },
  OATH: {
    step: [3, 1],
    url: 'serment',
    datalayerEvent: {
      ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Serment',
      funnel_complete: false,
    },
  },
  DOMAIN: {
    step: [3, 1],
    url: 'domaine',
    datalayerEvent: {
      ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Domaine',
      funnel_complete: false,
    },
  },
  DISPUTE_DATE: {
    step: [3, 1],
    url: 'date',
    datalayerEvent: {
      ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Date',
      funnel_complete: false,
    },
  },
  FACTS: {
    step: [3, 1],
    url: 'faits',
    datalayerEvent: {
      ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Faits',
      funnel_complete: false,
    },
  },
  ADDITIONAL_INFO: {
    step: [3, 1],
    url: 'informations-complementaires',
    datalayerEvent: {
      ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Informations complémentaires',
      funnel_complete: false,
    },
  },
  PROOF: {
    step: [3, 1],
    url: 'preuves',
    datalayerEvent: {
      ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Preuves',
      funnel_complete: false,
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'resume',
    datalayerEvent: {
      ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Récapitulatif',
      funnel_complete: false,
    },
  },
};

export const CLAIM_LEGAL_DOMAINS: SelectElementMapper<ClaimLegalDomain> = {
  [ClaimLegalDomain.WORK]: {
    value: ClaimLegalDomain.WORK,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.WORK),
  },
  [ClaimLegalDomain.HOME]: {
    value: ClaimLegalDomain.HOME,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.HOME),
  },
  [ClaimLegalDomain.CONTRACT]: {
    value: ClaimLegalDomain.CONTRACT,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.CONTRACT),
  },
  [ClaimLegalDomain.INSURANCE]: {
    value: ClaimLegalDomain.INSURANCE,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.INSURANCE),
  },
  [ClaimLegalDomain.TRAFFIC]: {
    value: ClaimLegalDomain.TRAFFIC,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.TRAFFIC),
  },
  [ClaimLegalDomain.INTERNET]: {
    value: ClaimLegalDomain.INTERNET,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.INTERNET),
  },
  [ClaimLegalDomain.OTHER]: {
    value: ClaimLegalDomain.OTHER,
    label: getClaimLegalDomainLabel(ClaimLegalDomain.OTHER),
  },
};

export const CLAIM_VEHICLE_DAMAGE_TARGETS: SelectElementMapper<VehicleDamageTarget> = {
  [VehicleDamageTarget.PERSON]: {
    value: VehicleDamageTarget.PERSON,
    label: getVehicleDamageTargetLabel(VehicleDamageTarget.PERSON),
  },
  [VehicleDamageTarget.MY_VEHICLE]: {
    value: VehicleDamageTarget.MY_VEHICLE,
    label: getVehicleDamageTargetLabel(VehicleDamageTarget.MY_VEHICLE),
  },
  [VehicleDamageTarget.OTHER_VEHICLE]: {
    value: VehicleDamageTarget.OTHER_VEHICLE,
    label: getVehicleDamageTargetLabel(VehicleDamageTarget.OTHER_VEHICLE),
  },
  [VehicleDamageTarget.OTHER]: {
    value: VehicleDamageTarget.OTHER,
    label: getVehicleDamageTargetLabel(VehicleDamageTarget.OTHER),
  },
};

export const CLAIM_VALUABLE_OBJECTS_DAMAGED_GOODS: SelectElementMapper<DamagedGood> = {
  [DamagedGood.JEWELRY]: {
    value: DamagedGood.JEWELRY,
    label: getDamagedGoodLabel(DamagedGood.JEWELRY),
    iconComponent: JewelryIconComponent,
  },
  [DamagedGood.HEARING_AID]: {
    value: DamagedGood.HEARING_AID,
    label: getDamagedGoodLabel(DamagedGood.HEARING_AID),
    iconComponent: EarIconComponent,
  },
  [DamagedGood.WEAPON]: {
    value: DamagedGood.WEAPON,
    label: getDamagedGoodLabel(DamagedGood.WEAPON),
    iconComponent: GunIconComponent,
  },
  [DamagedGood.PHOTOGRAHIC_EQUIPMENT]: {
    value: DamagedGood.PHOTOGRAHIC_EQUIPMENT,
    label: getDamagedGoodLabel(DamagedGood.PHOTOGRAHIC_EQUIPMENT),
    iconComponent: CameraIconComponent,
  },
  [DamagedGood.CLOTHES]: {
    value: DamagedGood.CLOTHES,
    label: getDamagedGoodLabel(DamagedGood.CLOTHES),
    iconComponent: FurcoatIconComponent,
  },
  [DamagedGood.PAINTING]: {
    value: DamagedGood.PAINTING,
    label: getDamagedGoodLabel(DamagedGood.PAINTING),
    iconComponent: PaintingIconComponent,
  },
  [DamagedGood.MUSIC_INSTRUMENT]: {
    value: DamagedGood.MUSIC_INSTRUMENT,
    label: getDamagedGoodLabel(DamagedGood.MUSIC_INSTRUMENT),
    iconComponent: PianoIconComponent,
  },
  [DamagedGood.OTHERS]: {
    value: DamagedGood.OTHERS,
    label: getDamagedGoodLabel(DamagedGood.OTHERS),
  },
};

export const DELAYS: SelectElementMapper<LegalDelay> = {
  [LegalDelay.YES]: {
    value: LegalDelay.YES,
    label: getLegalDelayLabel(LegalDelay.YES),
  },
  [LegalDelay.NO]: {
    value: LegalDelay.NO,
    label: getLegalDelayLabel(LegalDelay.NO),
  },
  [LegalDelay.DO_NOT_KNOW]: {
    value: LegalDelay.DO_NOT_KNOW,
    label: getLegalDelayLabel(LegalDelay.DO_NOT_KNOW),
  },
};

export const INSURANCE_ROUTES_MAPPING: { [key in ClaimType]: string } = {
  [ClaimType.ANIMAL]: ANIMAL_INSURANCE_BASE,
  [ClaimType.MOTOR_VEHICLE]: VEHICLE_BASE_URL,
  [ClaimType.BUILDING]: BUILDING_INSURANCE_BASE,
  [ClaimType.RC_HOUSEHOLD]: HOUSEHOLD_INSURANCE_BASE_URL,
  [ClaimType.VALUABLE_OBJECTS]: VALUABLE_OBJECTS_BASE,
  [ClaimType.CONSTRUCTION]: CONSTRUCTION_INSURANCE_BASE_URL,
  [ClaimType.TRAVEL]: TRAVEL_INSURANCE_BASE_URL,
  [ClaimType.LEGAL_PROTECTION]: LEGAL_PROTECTION_BASE_URL,
};

export const END_DATALAYER_EVENT_MAPPING: { [key in ClaimType]: FunnelDatalayerEvent } = {
  [ClaimType.ANIMAL]: {
    ...ANIMAL_FUNNEL_EVENT_BASE,
    funnel_complete: true,
    stage_name: 'fin',
    funnel_stage: 11,
  },
  [ClaimType.MOTOR_VEHICLE]: {
    ...VEHICLE_FUNNEL_EVENT_BASE,
    funnel_complete: true,
    stage_name: 'fin',
    funnel_stage: 15,
  },
  [ClaimType.BUILDING]: {
    ...BUILDING_FUNNEL_EVENT_BASE,
    funnel_complete: true,
    stage_name: 'fin',
    funnel_stage: 14,
  },
  [ClaimType.RC_HOUSEHOLD]: {
    ...HOUSEHOLD_FUNNEL_EVENT_BASE,
    funnel_complete: true,
    stage_name: 'fin',
    funnel_stage: 14,
  },
  [ClaimType.VALUABLE_OBJECTS]: {
    ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
    funnel_complete: true,
    stage_name: 'fin',
    funnel_stage: 14,
  },
  [ClaimType.CONSTRUCTION]: {
    ...CONSTRUCTION_FUNNEL_EVENT_BASE,
    funnel_complete: true,
    stage_name: 'fin',
    funnel_stage: 14,
  },
  [ClaimType.TRAVEL]: {
    ...TRAVEL_FUNNEL_EVENT_BASE,
    funnel_complete: true,
    stage_name: 'fin',
    funnel_stage: 14,
  },
  [ClaimType.LEGAL_PROTECTION]: {
    ...CLAIM_LEGAL_FUNNEL_EVENT_BASE,
    funnel_complete: true,
    stage_name: 'fin',
    funnel_stage: 14,
  },
};
