import { ContractToSignInfo } from 'src/app/_services/types/contract-template.types';
import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';
import {
  ConstructionType,
  getConstructionTypeEnumLabel,
} from 'src/app/enums/construction/construction-type.enum';
import { EntityType, getEntityTypeLabel } from 'src/app/enums/construction/entity-type.enum';
import {
  getProjectOwnerTypeLabel,
  ProjectOwnerType,
} from 'src/app/enums/construction/project-owner-type.enum';
import { ContractType } from 'src/app/enums/contract-type.enum';
import { InsuranceTypeEnum } from 'src/app/enums/insurance-type.enum';

import { SelectElementMapper } from '../../_services/types/commons.type';

export const CONSTRUCTION_INSURANCE_BASE_URL = 'construction';
export const CONSTRUCTION_INSURANCE_INPUT = 'formulaire';
export const CONSTRUCTION_INSURANCE_OFFER = 'offre';
export const CONSTRUCTION_INSURANCE_END = 'fin';
export const CONSTRUCTION_INSURANCE_END_URL = `${CONSTRUCTION_INSURANCE_BASE_URL}/fin`;

export const CONSTRUCTION_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Construction',
  product_category: 'Construction',
  product_name: 'Market',
  funnel_complete: false,
};

export const CONSTRUCTION_INSURANCE_END_EVENT: FunnelDatalayerEvent = {
  ...CONSTRUCTION_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 16,
  stage_name: 'Fin',
};

export const CONSTRUCTION_INPUT_FORM_BASE = `${CONSTRUCTION_INSURANCE_BASE_URL}/${CONSTRUCTION_INSURANCE_INPUT}/`;

export const CONSTRUCTION_INPUT_FORM_STEPS: {
  ENTITY_TYPE: Step;
  CONSTRUCTION_TYPE: Step;
  CONSTRUCTION_ADDRESS: Step;
  CONSTRUCTION_WORK: Step;
  ARCHITECT: Step;
  CIVIL_ENGINEER: Step;
  PROJECT_OWNER: Step;
  INSURANCES: Step;
  RISKS: Step;
  OFFERS: Step;
} = {
  ENTITY_TYPE: {
    step: [1, 1],
    url: 'type',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Type',
    },
  },
  CONSTRUCTION_TYPE: {
    step: [2, 1],
    url: 'construction',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Type de construction',
    },
  },
  CONSTRUCTION_ADDRESS: {
    step: [2, 1],
    url: 'adresse',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Adresse',
    },
  },
  CONSTRUCTION_WORK: {
    step: [3, 1],
    url: 'travaux',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Travaux',
    },
  },
  ARCHITECT: {
    step: [4, 1],
    url: 'architecte',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Architecte',
    },
  },
  CIVIL_ENGINEER: {
    step: [4, 1],
    url: 'ingenieur-civil',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Ingénieur civil',
    },
  },
  PROJECT_OWNER: {
    step: [4, 1],
    url: 'maitre-ouvrage',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: "Maitre d'ouvrage",
    },
  },
  INSURANCES: {
    step: [5, 1],
    url: 'assurance',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Assurance',
    },
  },
  RISKS: {
    step: [5, 1],
    url: 'risques',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Risque',
    },
  },
  OFFERS: {
    step: [6, 1],
    url: 'offres',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Offres',
    },
  },
};

export const CONSTRUCTION_OFFER_FORM_BASE = `${CONSTRUCTION_INSURANCE_BASE_URL}/${CONSTRUCTION_INSURANCE_OFFER}/`;

export const CONSTRUCTION_OFFER_FORM_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  CONTRACT_SIGNATURE: Step;
  CANCELLATION: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 1],
    url: 'connexion',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 1],
    url: 'inscription',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Informations personnelles',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [2, 1],
    url: 'signature',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 14,
      stage_name: 'Signatures',
    },
  },
  CANCELLATION: {
    step: [2, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...CONSTRUCTION_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: '(Option) résiliation',
    },
  },
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 construction',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.CONSTRUCTION,
  },
  {
    name: 'Mandat gestion construction',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.CONSTRUCTION,
  },
];

export const ENTITY_TYPES: SelectElementMapper<EntityType> = {
  [EntityType.OWNER]: {
    value: EntityType.OWNER,
    label: getEntityTypeLabel(EntityType.OWNER),
  },
  [EntityType.ARCHITECT]: {
    value: EntityType.ARCHITECT,
    label: getEntityTypeLabel(EntityType.ARCHITECT),
  },
  [EntityType.ENTERPRISE]: {
    value: EntityType.ENTERPRISE,
    label: getEntityTypeLabel(EntityType.ENTERPRISE),
  },
};

export const CONSTRUCTION_TYPES: SelectElementMapper<ConstructionType> = {
  [ConstructionType.NEW]: {
    value: ConstructionType.NEW,
    label: getConstructionTypeEnumLabel(ConstructionType.NEW),
  },
  [ConstructionType.EXTENSION]: {
    value: ConstructionType.EXTENSION,
    label: getConstructionTypeEnumLabel(ConstructionType.EXTENSION),
  },
  [ConstructionType.TRANSFORMATION]: {
    value: ConstructionType.TRANSFORMATION,
    label: getConstructionTypeEnumLabel(ConstructionType.TRANSFORMATION),
  },
  [ConstructionType.RENOVATION]: {
    value: ConstructionType.RENOVATION,
    label: getConstructionTypeEnumLabel(ConstructionType.RENOVATION),
  },
  [ConstructionType.OTHER]: {
    value: ConstructionType.OTHER,
    label: getConstructionTypeEnumLabel(ConstructionType.OTHER),
  },
};

export const PROJECT_OWNER_TYPES: SelectElementMapper<ProjectOwnerType> = {
  [ProjectOwnerType.OWNER]: {
    value: ProjectOwnerType.OWNER,
    label: getProjectOwnerTypeLabel(ProjectOwnerType.OWNER),
  },
  [ProjectOwnerType.BENEFICIARY]: {
    value: ProjectOwnerType.BENEFICIARY,
    label: getProjectOwnerTypeLabel(ProjectOwnerType.BENEFICIARY),
  },
  [ProjectOwnerType.TENANT]: {
    value: ProjectOwnerType.TENANT,
    label: getProjectOwnerTypeLabel(ProjectOwnerType.TENANT),
  },
  [ProjectOwnerType.OTHER]: {
    value: ProjectOwnerType.OTHER,
    label: getProjectOwnerTypeLabel(ProjectOwnerType.OTHER),
  },
};
