import { SelectElementMapper } from 'src/app/_services/types/commons.type';
import { ContractToSignInfo } from 'src/app/_services/types/contract-template.types';
import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';
import { ContractType } from 'src/app/enums/contract-type.enum';
import { InsuranceTypeEnum } from 'src/app/enums/insurance-type.enum';
import {
  getValuableObjectInsuranceType,
  getValuableObjectType,
  ValuableObjectInsuranceType,
  ValuableObjectType,
} from 'src/app/enums/valuable-objects.enum';
import { BanditIconComponent } from 'src/app/ui-kit/icons/bandit-icon.component';
import { CameraIconComponent } from 'src/app/ui-kit/icons/camera-icon.component';
import { EarIconComponent } from 'src/app/ui-kit/icons/ear-icon.component';
import { EarthquakeIconComponent } from 'src/app/ui-kit/icons/earthquake-icon.component';
import { FireIconComponent } from 'src/app/ui-kit/icons/fire-icon.component';
import { FurcoatIconComponent } from 'src/app/ui-kit/icons/furcoat-icon.component';
import { GunIconComponent } from 'src/app/ui-kit/icons/gun-icon.component';
import { InfinityIconComponent } from 'src/app/ui-kit/icons/infinity-icon.component';
import { JewelryIconComponent } from 'src/app/ui-kit/icons/jewelry-icon.component';
import { LossIconComponent } from 'src/app/ui-kit/icons/loss-icon.component';
import { PaintingIconComponent } from 'src/app/ui-kit/icons/painting-icon.component';
import { PianoIconComponent } from 'src/app/ui-kit/icons/piano-icon.component';
import { PipeIconComponent } from 'src/app/ui-kit/icons/pipe-icon.component';
import { ThiefIconComponent } from 'src/app/ui-kit/icons/thief-icon.component';

export const VALUABLE_OBJECTS_BASE = 'objets-de-valeur';
export const VALUABLE_OBJECTS_INPUT = 'formulaire';
export const VALUABLE_OBJECTS_OFFER = 'offre';
export const VALUABLE_OBJECTS_INSURANCE_END = 'fin';
export const VALUABLE_OBJECTS_INSURANCE_END_URL = `${VALUABLE_OBJECTS_BASE}/${VALUABLE_OBJECTS_INSURANCE_END}`;

export const VALUABLE_OBJECTS_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Objets Valeur',
  product_category: 'Objets Valeur',
  product_name: 'Market',
  funnel_complete: false,
};

export const VALUABLE_OBJECTS_INSURANCE_END_EVENT: FunnelDatalayerEvent = {
  ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 10,
  stage_name: 'Fin',
};

export const VALUABLE_OBJECTS_INPUT_BASE = `${VALUABLE_OBJECTS_BASE}/${VALUABLE_OBJECTS_INPUT}/`;

export const VALUABLE_OBJECTS_INPUT_FORM_STEPS: {
  CHOICE_OBJECTS: Step;
  INSURANCE_TYPE: Step;
  OFFERS: Step;
} = {
  CHOICE_OBJECTS: {
    step: [1, 1],
    url: 'choix-objets',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Objet',
    },
  },
  INSURANCE_TYPE: {
    step: [2, 1],
    url: 'type-de-garantie',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Type de garantie',
    },
  },
  OFFERS: {
    step: [3, 1],
    url: 'offers',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Offres',
    },
  },
};

export const VALUABLE_OBJECTS_OFFER_BASE = `${VALUABLE_OBJECTS_BASE}/${VALUABLE_OBJECTS_OFFER}/`;

export const VALUABLE_OBJECTS_OFFER_FORM_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  CONTRACT_SIGNATURE: Step;
  QUESTIONNARY: Step;
  CANCELLATION: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 1],
    url: 'connexion',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 1],
    url: 'inscription',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Informations personnelles',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [3, 1],
    url: 'signature-contrat',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Signatures',
    },
  },
  QUESTIONNARY: {
    step: [3, 1],
    url: 'questionnaire',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Questionnaire',
    },
  },
  CANCELLATION: {
    step: [3, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...VALUABLE_OBJECTS_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: '(Option) Résiliation',
    },
  },
};

export const VALUABLE_OBJECTS_TYPES: SelectElementMapper<ValuableObjectType> = {
  [ValuableObjectType.JEWELRY_AND_WATCHES]: {
    value: ValuableObjectType.JEWELRY_AND_WATCHES,
    label: getValuableObjectType(ValuableObjectType.JEWELRY_AND_WATCHES),
    iconComponent: JewelryIconComponent,
  },
  [ValuableObjectType.HEARING_AIDS]: {
    value: ValuableObjectType.HEARING_AIDS,
    label: getValuableObjectType(ValuableObjectType.HEARING_AIDS),
    iconComponent: EarIconComponent,
  },
  [ValuableObjectType.WEAPONS]: {
    value: ValuableObjectType.WEAPONS,
    label: getValuableObjectType(ValuableObjectType.WEAPONS),
    iconComponent: GunIconComponent,
  },
  [ValuableObjectType.PHOTOGRAPHIC_EQUIPMENT]: {
    value: ValuableObjectType.PHOTOGRAPHIC_EQUIPMENT,
    label: getValuableObjectType(ValuableObjectType.PHOTOGRAPHIC_EQUIPMENT),
    iconComponent: CameraIconComponent,
  },
  [ValuableObjectType.FUR_AND_CLOTH]: {
    value: ValuableObjectType.FUR_AND_CLOTH,
    label: getValuableObjectType(ValuableObjectType.FUR_AND_CLOTH),
    iconComponent: FurcoatIconComponent,
  },
  [ValuableObjectType.PAINTING_AND_SCULPTURES]: {
    value: ValuableObjectType.PAINTING_AND_SCULPTURES,
    label: getValuableObjectType(ValuableObjectType.PAINTING_AND_SCULPTURES),
    iconComponent: PaintingIconComponent,
  },
  [ValuableObjectType.MUSICAL_INSTRUMENTS]: {
    value: ValuableObjectType.MUSICAL_INSTRUMENTS,
    label: getValuableObjectType(ValuableObjectType.MUSICAL_INSTRUMENTS),
    iconComponent: PianoIconComponent,
  },
  [ValuableObjectType.OTHER]: {
    value: ValuableObjectType.OTHER,
    label: getValuableObjectType(ValuableObjectType.OTHER),
  },
};

export const VALUABLE_OBJECTS_INSURANCE_TYPES: SelectElementMapper<ValuableObjectInsuranceType> = {
  [ValuableObjectInsuranceType.NATURAL_DISASTER]: {
    value: ValuableObjectInsuranceType.NATURAL_DISASTER,
    label: getValuableObjectInsuranceType(ValuableObjectInsuranceType.NATURAL_DISASTER),
    iconComponent: FireIconComponent,
  },
  [ValuableObjectInsuranceType.THEFT]: {
    value: ValuableObjectInsuranceType.THEFT,
    label: getValuableObjectInsuranceType(ValuableObjectInsuranceType.THEFT),
    iconComponent: ThiefIconComponent,
  },
  [ValuableObjectInsuranceType.OUTSIDE_THEFT]: {
    value: ValuableObjectInsuranceType.OUTSIDE_THEFT,
    label: getValuableObjectInsuranceType(ValuableObjectInsuranceType.OUTSIDE_THEFT),
    iconComponent: BanditIconComponent,
  },
  [ValuableObjectInsuranceType.EARTHQUAKE]: {
    value: ValuableObjectInsuranceType.EARTHQUAKE,
    label: getValuableObjectInsuranceType(ValuableObjectInsuranceType.EARTHQUAKE),
    iconComponent: EarthquakeIconComponent,
  },
  [ValuableObjectInsuranceType.WATER_DAMAGE]: {
    value: ValuableObjectInsuranceType.WATER_DAMAGE,
    label: getValuableObjectInsuranceType(ValuableObjectInsuranceType.WATER_DAMAGE),
    iconComponent: PipeIconComponent,
  },
  [ValuableObjectInsuranceType.LOSS]: {
    value: ValuableObjectInsuranceType.LOSS,
    label: getValuableObjectInsuranceType(ValuableObjectInsuranceType.LOSS),
    iconComponent: LossIconComponent,
  },
  [ValuableObjectInsuranceType.ALL_RISKS]: {
    value: ValuableObjectInsuranceType.ALL_RISKS,
    label: getValuableObjectInsuranceType(ValuableObjectInsuranceType.ALL_RISKS),
    iconComponent: InfinityIconComponent,
  },
};

export const INSURANCE_TYPE_RECOMMENDED = ValuableObjectInsuranceType.ALL_RISKS;

export const VALUABLE_OBJECTS_INSURANCE_ADVANTAGES = [
  {
    title: $localize`Pense à ce qui est important pour toi`,
    description: $localize`Réfléchis à ce qui te tient à cœur en termes de protection. Par exemple, si tu crains les incendies ou les fuites d'eau, c'est important de prendre ces couvertures de base.`,
  },
  {
    title: $localize`Regarde autour de toi`,
    description: $localize`Observe l'environnement du bien à assurer et ce qui se passe dans cette région. Si tu es dans une zone sujette aux tremblements de terre, ajoute cette couverture. Si tu as un beau jardin, pense à une couverture pour les dommages causés aux plantes.`,
  },
  {
    title: $localize`Protège tes affaires`,
    description: $localize`Si tu as investis dans des installations techniques, pense à une couverture spéciale pour celle-ci. Et si tu as des fenêtres en verre, la couverture pour bris de glace est importante.`,
  },
];

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 objets de valeur',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.VALUABLE_OBJECTS,
  },
  {
    name: 'Mandat gestion objets de valeur',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.VALUABLE_OBJECTS,
  },
];
