import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-book-open-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 26.6666H6.93332C5.43985 26.6666 4.69311 26.6666 4.12268 26.3759C3.62092 26.1203 3.21297 25.7123 2.95731 25.2106C2.66666 24.6401 2.66666 23.8934 2.66666 22.3999V9.59992C2.66666 8.10644 2.66666 7.35971 2.95731 6.78928C3.21297 6.28751 3.62092 5.87956 4.12268 5.6239C4.69311 5.33325 5.43985 5.33325 6.93332 5.33325H7.46666C10.4536 5.33325 11.9471 5.33325 13.0879 5.91455C14.0915 6.42588 14.9074 7.24177 15.4187 8.2453C16 9.38616 16 10.8796 16 13.8666M16 26.6666V13.8666M16 26.6666H25.0667C26.5601 26.6666 27.3069 26.6666 27.8773 26.3759C28.3791 26.1203 28.787 25.7123 29.0427 25.2106C29.3333 24.6401 29.3333 23.8934 29.3333 22.3999V9.59992C29.3333 8.10644 29.3333 7.35971 29.0427 6.78928C28.787 6.28751 28.3791 5.87956 27.8773 5.6239C27.3069 5.33325 26.5601 5.33325 25.0667 5.33325H24.5333C21.5464 5.33325 20.0529 5.33325 18.912 5.91455C17.9085 6.42588 17.0926 7.24177 16.5813 8.2453C16 9.38616 16 10.8796 16 13.8666"
        [attr.stroke]="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class BookOpenIconComponent extends BaseIconComponent {}
