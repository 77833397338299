import { DogRace } from 'src/app/_services/types/animal.types';
import { SelectElementMapper } from 'src/app/_services/types/commons.type';
import { ContractToSignInfo } from 'src/app/_services/types/contract-template.types';
import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';
import { Animal, getAnimalLabel } from 'src/app/enums/animal/animal.enum';
import { AnimalCovers } from 'src/app/enums/animal/animal-covers.enum';
import { ContractType } from 'src/app/enums/contract-type.enum';
import { InsuranceTypeEnum } from 'src/app/enums/insurance-type.enum';
import { CatIconComponent } from 'src/app/ui-kit/icons/cat-icon.component';
import { DogIconComponent } from 'src/app/ui-kit/icons/dog-icon.component';

export const ANIMAL_INSURANCE_BASE = 'animal';
export const ANIMAL_INSURANCE_INPUT = 'formulaire';
export const ANIMAL_INSURANCE_OFFER = 'offre';
export const ANIMAL_INSURANCE_END = 'fin';
export const ANIMAL_INSURANCE_END_URL = `${ANIMAL_INSURANCE_BASE}/fin`;

export const ANIMAL_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Animal',
  product_category: 'Animal',
  product_name: 'Market',
  funnel_complete: false,
};

export const ANIMAL_INSURANCE_END_EVENT: FunnelDatalayerEvent = {
  ...ANIMAL_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 11,
  stage_name: 'Fin',
};

export const ANIMAL_INPUT_FORM_BASE = `${ANIMAL_INSURANCE_BASE}/${ANIMAL_INSURANCE_INPUT}/`;

export const ANIMAL_INPUT_FORM_STEPS: {
  INFO: Step;
  START_DATE: Step;
  DETAILS: Step;
  OFFERS: Step;
} = {
  INFO: {
    step: [1, 1],
    url: 'informations',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Informations',
    },
  },
  START_DATE: {
    step: [1, 1],
    url: 'date',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Date de début',
    },
  },
  DETAILS: {
    step: [1, 1],
    url: 'details',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Détails',
    },
  },
  OFFERS: {
    step: [2, 1],
    url: 'offres',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Offres',
    },
  },
};

export const ANIMAL_OFFER_FORM_BASE = `${ANIMAL_INSURANCE_BASE}/${ANIMAL_INSURANCE_OFFER}/`;

export const ANIMAL_OFFER_FORM_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  SUMMARY: Step;
  CONTRACT_SIGNATURE: Step;
  CANCELLATION: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 1],
    url: 'connexion',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 1],
    url: 'inscription',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Informations personnelles',
    },
  },
  SUMMARY: {
    step: [3, 1],
    url: 'recapitulatif',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Récapitulatif',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [4, 1],
    url: 'signature',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Signatures',
    },
  },
  CANCELLATION: {
    step: [4, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...ANIMAL_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: '(Option) Résiliation',
    },
  },
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 animal',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.ANIMAL,
  },
  {
    name: 'Mandat gestion animal',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.ANIMAL,
  },
];

export const ANIMAL_TYPES: SelectElementMapper<Animal> = {
  [Animal.DOG]: {
    value: Animal.DOG,
    label: getAnimalLabel(Animal.DOG),
    iconComponent: DogIconComponent,
  },
  [Animal.CAT]: {
    value: Animal.CAT,
    label: getAnimalLabel(Animal.CAT),
    iconComponent: CatIconComponent,
  },
};

export const DEDUCTIBLE_VALUES: { [key in Animal]: number[] } = {
  [Animal.CAT]: [150, 300, 600],
  [Animal.DOG]: [250, 500, 1000],
};

export const ANIMAL_COVERS: SelectElementMapper<AnimalCovers> = {
  [AnimalCovers.VETERINARY_FEE]: {
    value: AnimalCovers.VETERINARY_FEE,
    label: $localize`Frais vétérinaires`,
  },
  [AnimalCovers.EXAM]: {
    value: AnimalCovers.EXAM,
    label: $localize`Tests de diagnostic & examen radiologique`,
  },
  [AnimalCovers.MEDECINE]: {
    value: AnimalCovers.MEDECINE,
    label: $localize`Médicaments`,
  },
  [AnimalCovers.SURGERY]: {
    value: AnimalCovers.SURGERY,
    label: $localize`Interventions chirurgicales`,
  },
  [AnimalCovers.ACCOMODATION]: {
    value: AnimalCovers.ACCOMODATION,
    label: $localize`Frais de séjour & de repas`,
  },
  [AnimalCovers.EMERGENCY_SUPPLEMENTS]: {
    value: AnimalCovers.EMERGENCY_SUPPLEMENTS,
    label: $localize`Suppléments d'urgence`,
  },
  [AnimalCovers.TRANSPORTATION]: {
    value: AnimalCovers.TRANSPORTATION,
    label: $localize`Transport`,
  },
  [AnimalCovers.REHABILITATION]: {
    value: AnimalCovers.REHABILITATION,
    label: $localize`Réhabilitation`,
  },
  [AnimalCovers.ALTERNATIVE_MEDICINE]: {
    value: AnimalCovers.ALTERNATIVE_MEDICINE,
    label: $localize`Médecine complémentaire / alternative`,
  },
  [AnimalCovers.PREVENTION]: {
    value: AnimalCovers.PREVENTION,
    label: $localize`Prévention`,
  },
  [AnimalCovers.DENTAL]: {
    value: AnimalCovers.DENTAL,
    label: $localize`Médicine dentaire`,
  },
  [AnimalCovers.ADDITIONAL_SERVICES]: {
    value: AnimalCovers.ADDITIONAL_SERVICES,
    label: $localize`Services supplémentaires`,
  },
  [AnimalCovers.HEREDITARY_ILLNESS]: {
    value: AnimalCovers.HEREDITARY_ILLNESS,
    label: $localize`Maladies héréditaires / raciales`,
  },
  [AnimalCovers.CHEMO_RADIO]: {
    value: AnimalCovers.CHEMO_RADIO,
    label: $localize`Chimiothéraphie et radiothérapie`,
  },
};

export const DOG_RACES: DogRace[] = [
  {
    value: 'Mischling, klein / Chien mâtiné, petit (0-10kg)',
    id: 617,
    label: $localize`Mischling, klein / Chien mâtiné, petit (bis 10kg)`,
  },
  {
    value: 'Mischling, mittel / Chien mâtiné, moyen (10-25kg)',
    id: 618,
    label: $localize`Mischling, mittel / Chien mâtiné, moyen (bis 25kg)`,
  },
  {
    value: 'Mischling, gross / Chien mâtiné, grand (25-40kg)',
    id: 616,
    label: $localize`Mischling, gross / Chien mâtiné, grand (bis 40kg)`,
  },
  {
    value: 'Mischling, riesig / Chien mâtiné, énorme (>40kg)',
    id: 619,
    label: $localize`Mischling, riesig / Chien mâtiné, énorme (ab 41kg)`,
  },
  {
    value: 'Affenpinscher (0-10kg)',
    id: 352,
    label: $localize`Affenpinscher`,
  },
  {
    value: 'Afghanischer Windhund (Afghan Hound) (25-40kg)',
    id: 353,
    label: $localize`Afghanischer Windhund (Afghan Hound)`,
  },
  {
    value: 'Aïdi (Chien de montagne de l’Atlas) (10-25kg)',
    id: 354,
    label: $localize`Aïdi (Chien de montagne de l’Atlas)`,
  },
  {
    value: 'Airedale Terrier (10-25kg)',
    id: 355,
    label: $localize`Airedale Terrier`,
  },
  {
    value: 'Akita (>40kg)',
    id: 356,
    label: $localize`Akita`,
  },
  {
    value: 'Alaskan Husky (10-25kg)',
    id: 357,
    label: $localize`Alaskan Husky`,
  },
  {
    value: 'Alaskan Malamute (25-40kg)',
    id: 358,
    label: $localize`Alaskan Malamute`,
  },
  {
    value: 'Alpenländische Dachsbracke / Basset des Alpes (10-25kg)',
    id: 359,
    label: $localize`Alpenländische Dachsbracke / Basset des Alpes`,
  },
  {
    value: 'American Akita (25-40kg)',
    id: 360,
    label: $localize`American Akita`,
  },
  {
    value: 'American Bull Terrier (10-25kg)',
    id: 361,
    label: $localize`American Bull Terrier`,
  },
  {
    value: 'American Bulldog (25-40kg)',
    id: 362,
    label: $localize`American Bulldog`,
  },
  {
    value: 'American Cocker Spaniel (10-25kg)',
    id: 363,
    label: $localize`American Cocker Spaniel`,
  },
  {
    value: 'American Eskimo Dog (0-10kg)',
    id: 364,
    label: $localize`American Eskimo Dog`,
  },
  {
    value: 'American Foxhound (10-25kg)',
    id: 365,
    label: $localize`American Foxhound`,
  },
  {
    value: 'American Hairless Terrier (0-10kg)',
    id: 366,
    label: $localize`American Hairless Terrier`,
  },
  {
    value: 'American Staffordshire Terrier (10-25kg)',
    id: 367,
    label: $localize`American Staffordshire Terrier`,
  },
  {
    value: 'American Water Spaniel (10-25kg)',
    id: 368,
    label: $localize`American Water Spaniel`,
  },
  {
    value: 'Anglo-Francais de Petite Venerie (10-25kg)',
    id: 369,
    label: $localize`Anglo-Francais de Petite Venerie`,
  },
  {
    value: 'Anjing Kintamani-Bali (10-25kg)',
    id: 370,
    label: $localize`Anjing Kintamani-Bali`,
  },
  {
    value: 'Appenzeller Sennenhund / Bouvier Appenzellois (10-25kg)',
    id: 371,
    label: $localize`Appenzeller Sennenhund / Bouvier Appenzellois`,
  },
  {
    value: 'Ardennen Treibhund / Bouvier des Ardennes (10-25kg)',
    id: 372,
    label: $localize`Ardennen Treibhund / Bouvier des Ardennes`,
  },
  {
    value: 'Ariegeois (25-40kg)',
    id: 373,
    label: $localize`Ariegeois`,
  },
  {
    value: 'Australian Cattle Dog (10-25kg)',
    id: 374,
    label: $localize`Australian Cattle Dog`,
  },
  {
    value: 'Australian Kelpie (10-25kg)',
    id: 375,
    label: $localize`Australian Kelpie`,
  },
  {
    value: 'Australian Shepherd (25-40kg)',
    id: 376,
    label: $localize`Australian Shepherd`,
  },
  {
    value: 'Australian Silky Terrier (0-10kg)',
    id: 377,
    label: $localize`Australian Silky Terrier`,
  },
  {
    value: 'Australian Stumpy Tail Cattle Dog (10-25kg)',
    id: 378,
    label: $localize`Australian Stumpy Tail Cattle Dog`,
  },
  {
    value: 'Australian Terrier (0-10kg)',
    id: 379,
    label: $localize`Australian Terrier`,
  },
  {
    value: 'Azawakh (10-25kg)',
    id: 380,
    label: $localize`Azawakh`,
  },
  {
    value: 'Barbet (10-25kg)',
    id: 381,
    label: $localize`Barbet`,
  },
  {
    value: 'Bardino (25-40kg)',
    id: 382,
    label: $localize`Bardino`,
  },
  {
    value: 'Barsoi / Russkaya Psovaya Borzaya (25-40kg)',
    id: 383,
    label: $localize`Barsoi / Russkaya Psovaya Borzaya`,
  },
  {
    value: 'Basenji (0-10kg)',
    id: 384,
    label: $localize`Basenji`,
  },
  {
    value: 'Basset Artesien Normand (10-25kg)',
    id: 385,
    label: $localize`Basset Artesien Normand`,
  },
  {
    value: 'Basset Bleu de Gascogne (10-25kg)',
    id: 386,
    label: $localize`Basset Bleu de Gascogne`,
  },
  {
    value: 'Basset Fauve de Bretagne (10-25kg)',
    id: 387,
    label: $localize`Basset Fauve de Bretagne`,
  },
  {
    value: 'Basset Hound (10-25kg)',
    id: 388,
    label: $localize`Basset Hound`,
  },
  {
    value: 'Bayerischer Gebirgsschweisshund / Chien de Rouge de Baviere (10-25kg)',
    id: 389,
    label: $localize`Bayerischer Gebirgsschweisshund / Chien de Rouge de Baviere`,
  },
  {
    value: 'Beagle (0-10kg)',
    id: 390,
    label: $localize`Beagle`,
  },
  {
    value: 'Beagle Harrier (10-25kg)',
    id: 391,
    label: $localize`Beagle Harrier`,
  },
  {
    value: 'Bearded Collie (10-25kg)',
    id: 392,
    label: $localize`Bearded Collie`,
  },
  {
    value: 'Bedlington Terrier (0-10kg)',
    id: 393,
    label: $localize`Bedlington Terrier`,
  },
  {
    value: 'Belgischer Schäferhund / Chien de Berger Belge  (10-25kg)',
    id: 394,
    label: $localize`Belgischer Schäferhund / Chien de Berger Belge`,
  },
  {
    value: 'Berger de Beauce (25-40kg)',
    id: 395,
    label: $localize`Berger de Beauce`,
  },
  {
    value: 'Berger de Picardie / Berger Picard (10-25kg)',
    id: 396,
    label: $localize`Berger de Picardie / Berger Picard`,
  },
  {
    value: 'Berger de Savoie (25-40kg)',
    id: 397,
    label: $localize`Berger de Savoie`,
  },
  {
    value: 'Berner Sennenhund / Boivier Bernois (>40kg)',
    id: 398,
    label: $localize`Berner Sennenhund / Boivier Bernois`,
  },
  {
    value: 'Bernhardiner (St. Bernhardshund) / Chien du Mont Saint-Bernard (>40kg)',
    id: 399,
    label: $localize`Bernhardiner (St. Bernhardshund) / Chien du Mont Saint-Bernard`,
  },
  {
    value: 'Bichon (0-10kg)',
    id: 400,
    label: $localize`Bichon`,
  },
  {
    value: 'Bichon Frise / Bichon a poil frise (0-10kg)',
    id: 401,
    label: $localize`Bichon Frise / Bichon a poil frise`,
  },
  {
    value: 'Billy (25-40kg)',
    id: 402,
    label: $localize`Billy`,
  },
  {
    value: 'Black and Tan Coonhound (25-40kg)',
    id: 403,
    label: $localize`Black and Tan Coonhound`,
  },
  {
    value: 'Bluthund / Chien de Saint Hubert / Bloodhound (>40kg)',
    id: 404,
    label: $localize`Bluthund / Chien de Saint Hubert / Bloodhound`,
  },
  {
    value: 'Boerboel (>40kg)',
    id: 405,
    label: $localize`Boerboel`,
  },
  {
    value: 'Bolognese (0-10kg)',
    id: 406,
    label: $localize`Bolognese`,
  },
  {
    value: 'Bolonka Zwetna (0-10kg)',
    id: 407,
    label: $localize`Bolonka Zwetna`,
  },
  {
    value: 'Border Collie (10-25kg)',
    id: 408,
    label: $localize`Border Collie`,
  },
  {
    value: 'Border Terrier (0-10kg)',
    id: 409,
    label: $localize`Border Terrier`,
  },
  {
    value: 'Bosanski Ostrodlaki Gonic / Barak (10-25kg)',
    id: 410,
    label: $localize`Bosanski Ostrodlaki Gonic / Barak`,
  },
  {
    value: 'Boston Terrier (0-10kg)',
    id: 411,
    label: $localize`Boston Terrier`,
  },
  {
    value: 'Bouvier des Flandres / Vlaamse Koehond (25-40kg)',
    id: 412,
    label: $localize`Bouvier des Flandres / Vlaamse Koehond`,
  },
  {
    value: 'Bracco Italiano (25-40kg)',
    id: 413,
    label: $localize`Bracco Italiano`,
  },
  {
    value: 'Brandlbracke - Vieräugl (10-25kg)',
    id: 414,
    label: $localize`Brandlbracke - Vieräugl`,
  },
  {
    value: "Braque d'Auvergne (10-25kg)",
    id: 415,
    label: $localize`Braque d'Auvergne`,
  },
  {
    value: "Braque de l'Ariege (25-40kg)",
    id: 416,
    label: $localize`Braque de l'Ariege`,
  },
  {
    value: 'Braque du Bourbonnais (10-25kg)',
    id: 417,
    label: $localize`Braque du Bourbonnais`,
  },
  {
    value: 'Braque français (Französischer Vorstehhund) (10-25kg)',
    id: 418,
    label: $localize`Braque français (Französischer Vorstehhund)`,
  },
  {
    value: 'Braque Saint-Germain (10-25kg)',
    id: 419,
    label: $localize`Braque Saint-Germain`,
  },
  {
    value: 'Briard / Berger de Brie  (25-40kg)',
    id: 420,
    label: $localize`Briard / Berger de Brie`,
  },
  {
    value: 'Briquet Griffon Vendéen (10-25kg)',
    id: 421,
    label: $localize`Briquet Griffon Vendéen`,
  },
  {
    value: 'Broholmer (>40kg)',
    id: 422,
    label: $localize`Broholmer`,
  },
  {
    value: 'Bull Terrier (25-40kg)',
    id: 423,
    label: $localize`Bull Terrier`,
  },
  {
    value: 'Bullartiger Terrier, klein (0-10kg)',
    id: 425,
    label: $localize`Bullartiger Terrier, klein (bis 10kg)`,
  },
  {
    value: 'Bullartiger Terrier, mittel (10-25kg)',
    id: 426,
    label: $localize`Bullartiger Terrier, mittel (bis 25kg)`,
  },
  {
    value: 'Bullartiger Terrier, gross (25-40kg)',
    id: 424,
    label: $localize`Bullartiger Terrier, gross (bis 40kg)`,
  },
  {
    value: 'Bullartiger Terrier, riesig (>40kg)',
    id: 427,
    label: $localize`Bullartiger Terrier, riesig (ab 41kg)`,
  },
  {
    value: 'Bullmastiff (>40kg)',
    id: 428,
    label: $localize`Bullmastiff`,
  },
  {
    value: 'Buryato / Mongolischer Hirtenhund (25-40kg)',
    id: 429,
    label: $localize`Buryato / Mongolischer Hirtenhund`,
  },
  {
    value: 'Ca de Bestiar / Perro de Pastor Mallorquin (25-40kg)',
    id: 430,
    label: $localize`Ca de Bestiar / Perro de Pastor Mallorquin`,
  },
  {
    value: 'Cairn Terrier (0-10kg)',
    id: 431,
    label: $localize`Cairn Terrier`,
  },
  {
    value: 'Canaan Dog (10-25kg)',
    id: 432,
    label: $localize`Canaan Dog`,
  },
  {
    value: 'Canadian Eskimo Dog (25-40kg)',
    id: 433,
    label: $localize`Canadian Eskimo Dog`,
  },
  {
    value: 'Canadian Inuit Dog (Husky) (25-40kg)',
    id: 434,
    label: $localize`Canadian Inuit Dog (Husky)`,
  },
  {
    value: 'Cane Corso Italiano (>40kg)',
    id: 435,
    label: $localize`Cane Corso Italiano`,
  },
  {
    value: 'Cane da Pastore Bergamasco (25-40kg)',
    id: 436,
    label: $localize`Cane da Pastore Bergamasco`,
  },
  {
    value: 'Cane da Pastore Maremmano Abruzzese (25-40kg)',
    id: 437,
    label: $localize`Cane da Pastore Maremmano Abruzzese`,
  },
  {
    value: 'Cao da Serra da Estrela (25-40kg)',
    id: 438,
    label: $localize`Cao da Serra da Estrela`,
  },
  {
    value: 'Cao da Serra de Aires / Portugiesischer Hütehund (10-25kg)',
    id: 439,
    label: $localize`Cao da Serra de Aires / Portugiesischer Hütehund`,
  },
  {
    value: 'Cao de agua Portugues / Portugiesischer Wasserhund (10-25kg)',
    id: 440,
    label: $localize`Cao de agua Portugues / Portugiesischer Wasserhund`,
  },
  {
    value: 'Cao de Castro Laboreiro (25-40kg)',
    id: 441,
    label: $localize`Cao de Castro Laboreiro`,
  },
  {
    value: 'Cao de Gado Transmontano (>40kg)',
    id: 442,
    label: $localize`Cao de Gado Transmontano`,
  },
  {
    value: 'Cao Fila de Sao Miguel (25-40kg)',
    id: 443,
    label: $localize`Cao Fila de Sao Miguel`,
  },
  {
    value: 'Cavalier King Charles Spaniel (0-10kg)',
    id: 444,
    label: $localize`Cavalier King Charles Spaniel`,
  },
  {
    value: 'Ceskoslovensky Vlciak (10-25kg)',
    id: 445,
    label: $localize`Ceskoslovensky Vlciak`,
  },
  {
    value: 'Cesky Fousek (25-40kg)',
    id: 446,
    label: $localize`Cesky Fousek`,
  },
  {
    value: 'Cesky Terrier (0-10kg)',
    id: 447,
    label: $localize`Cesky Terrier`,
  },
  {
    value: 'Chart Polski / Polnischer Windhund / Levrier Polonais (25-40kg)',
    id: 448,
    label: $localize`Chart Polski / Polnischer Windhund / Levrier Polonais`,
  },
  {
    value: 'Chesapeake Bay Retriever (25-40kg)',
    id: 449,
    label: $localize`Chesapeake Bay Retriever`,
  },
  {
    value: "Chien d'Artois (25-40kg)",
    id: 450,
    label: $localize`Chien d'Artois`,
  },
  {
    value: 'Chihuahueno (0-10kg)',
    id: 451,
    label: $localize`Chihuahueno`,
  },
  {
    value: 'Chin (0-10kg)',
    id: 452,
    label: $localize`Chin`,
  },
  {
    value: 'Chinese Crested Dog / Chinesischer Schopfhund (0-10kg)',
    id: 453,
    label: $localize`Chinese Crested Dog / Chinesischer Schopfhund`,
  },
  {
    value: 'Chinook (25-40kg)',
    id: 454,
    label: $localize`Chinook`,
  },
  {
    value: 'Chodsky Pes (10-25kg)',
    id: 455,
    label: $localize`Chodsky Pes`,
  },
  {
    value: 'Chortaj (10-25kg)',
    id: 456,
    label: $localize`Chortaj`,
  },
  {
    value: 'Chow Chow (0-10kg)',
    id: 457,
    label: $localize`Chow Chow`,
  },
  {
    value: 'Cimarron Uruguayo (25-40kg)',
    id: 458,
    label: $localize`Cimarron Uruguayo`,
  },
  {
    value: 'Ciobanesc Romanesc Carpatin (25-40kg)',
    id: 459,
    label: $localize`Ciobanesc Romanesc Carpatin`,
  },
  {
    value: 'Ciobanesc Romanesc de Bucovina (>40kg)',
    id: 460,
    label: $localize`Ciobanesc Romanesc de Bucovina`,
  },
  {
    value: 'Ciobanesc Romanesc Mioritic (10-25kg)',
    id: 461,
    label: $localize`Ciobanesc Romanesc Mioritic`,
  },
  {
    value: "Cirneco dell'Etna (0-10kg)",
    id: 462,
    label: $localize`Cirneco dell'Etna`,
  },
  {
    value: 'Clumber Spaniel (25-40kg)',
    id: 463,
    label: $localize`Clumber Spaniel`,
  },
  {
    value: 'Collie Rough (10-25kg)',
    id: 464,
    label: $localize`Collie Rough`,
  },
  {
    value: 'Collie Smooth (10-25kg)',
    id: 465,
    label: $localize`Collie Smooth`,
  },
  {
    value: 'Continental Bulldog (25-40kg)',
    id: 466,
    label: $localize`Continental Bulldog`,
  },
  {
    value: 'Coton De Tulear (0-10kg)',
    id: 467,
    label: $localize`Coton De Tulear`,
  },
  {
    value: 'Crnogorski Planinski Gonic (10-25kg)',
    id: 468,
    label: $localize`Crnogorski Planinski Gonic`,
  },
  {
    value: 'Curly Coated Retriever (10-25kg)',
    id: 469,
    label: $localize`Curly Coated Retriever`,
  },
  {
    value: 'Cursinu (10-25kg)',
    id: 470,
    label: $localize`Cursinu`,
  },
  {
    value: 'Dackel (Dachshund) / Teckel (0-10kg)',
    id: 471,
    label: $localize`Dackel (Dachshund) / Teckel`,
  },
  {
    value: 'Dalmatiner / Dalmatien / Dalmatinski Pas (10-25kg)',
    id: 472,
    label: $localize`Dalmatiner / Dalmatien / Dalmatinski Pas`,
  },
  {
    value: 'Dandie Dinmont Terrier (0-10kg)',
    id: 473,
    label: $localize`Dandie Dinmont Terrier`,
  },
  {
    value: 'Dansk-Svensk Gardshund (0-10kg)',
    id: 474,
    label: $localize`Dansk-Svensk Gardshund`,
  },
  {
    value: 'Deerhound / Schottischer Hirschhund (>40kg)',
    id: 475,
    label: $localize`Deerhound / Schottischer Hirschhund`,
  },
  {
    value: 'Deutsch Drahthaar (25-40kg)',
    id: 476,
    label: $localize`Deutsch Drahthaar`,
  },
  {
    value: 'Deutsch Kurzhaar (25-40kg)',
    id: 477,
    label: $localize`Deutsch Kurzhaar`,
  },
  {
    value: 'Deutsch Langhaar (25-40kg)',
    id: 478,
    label: $localize`Deutsch Langhaar`,
  },
  {
    value: 'Deutsch Stichelhaar (10-25kg)',
    id: 479,
    label: $localize`Deutsch Stichelhaar`,
  },
  {
    value: 'Deutsche Bracke / Brachet allemand (10-25kg)',
    id: 480,
    label: $localize`Deutsche Bracke / Brachet allemand`,
  },
  {
    value: 'Deutsche Dogge / Dogue Allemand (>40kg)',
    id: 481,
    label: $localize`Deutsche Dogge / Dogue Allemand`,
  },
  {
    value: 'Deutscher Boxer (25-40kg)',
    id: 482,
    label: $localize`Deutscher Boxer`,
  },
  {
    value: 'Deutscher Pinscher / Pinscher allemand (10-25kg)',
    id: 483,
    label: $localize`Deutscher Pinscher / Pinscher allemand`,
  },
  {
    value: 'Deutscher Schäferhund / Berger allemand (25-40kg)',
    id: 484,
    label: $localize`Deutscher Schäferhund / Berger allemand`,
  },
  {
    value: 'Deutscher Vorstehhund (10-25kg)',
    id: 485,
    label: $localize`Deutscher Vorstehhund`,
  },
  {
    value: 'Deutscher Wachtelhund (10-25kg)',
    id: 486,
    label: $localize`Deutscher Wachtelhund`,
  },
  {
    value: 'Do Khyi / Dogue du Tibet (>40kg)',
    id: 487,
    label: $localize`Do Khyi / Dogue du Tibet`,
  },
  {
    value: 'Dobermann (>40kg)',
    id: 488,
    label: $localize`Dobermann`,
  },
  {
    value: 'Dogo Argentino / Argentinische Dogge / Dogue Argentin (25-40kg)',
    id: 489,
    label: $localize`Dogo Argentino / Argentinische Dogge / Dogue Argentin`,
  },
  {
    value: 'Dogo Canario (>40kg)',
    id: 490,
    label: $localize`Dogo Canario`,
  },
  {
    value: 'Dogue de Bordeaux (>40kg)',
    id: 491,
    label: $localize`Dogue de Bordeaux`,
  },
  {
    value: 'Drentsche Patrijshond (10-25kg)',
    id: 492,
    label: $localize`Drentsche Patrijshond`,
  },
  {
    value: 'Drever (10-25kg)',
    id: 493,
    label: $localize`Drever`,
  },
  {
    value: 'Dunker (10-25kg)',
    id: 494,
    label: $localize`Dunker`,
  },
  {
    value: "Eesti Hagijas / Estnische Bracke / Chien Courant d'Estonie  (25-40kg)",
    id: 495,
    label: $localize`Eesti Hagijas / Estnische Bracke / Chien Courant d'Estonie`,
  },
  {
    value: 'Englische Bulldogge (10-25kg)',
    id: 496,
    label: $localize`Englische Bulldogge`,
  },
  {
    value: 'English Cocker Spaniel (10-25kg)',
    id: 497,
    label: $localize`English Cocker Spaniel`,
  },
  {
    value: 'English Foxhound (25-40kg)',
    id: 498,
    label: $localize`English Foxhound`,
  },
  {
    value: 'English Pointer (25-40kg)',
    id: 499,
    label: $localize`English Pointer`,
  },
  {
    value: 'English Setter (10-25kg)',
    id: 500,
    label: $localize`English Setter`,
  },
  {
    value: 'English Springer Spaniel (10-25kg)',
    id: 501,
    label: $localize`English Springer Spaniel`,
  },
  {
    value: 'English Toy Terrier (10-25kg)',
    id: 502,
    label: $localize`English Toy Terrier`,
  },
  {
    value: "Entlebucher Sennenhund / Bouvier de l'Entlebuch (10-25kg)",
    id: 503,
    label: $localize`Entlebucher Sennenhund / Bouvier de l'Entlebuch`,
  },
  {
    value: 'Epagneul Bleu de Picardie (10-25kg)',
    id: 504,
    label: $localize`Epagneul Bleu de Picardie`,
  },
  {
    value: 'Epagneul Breton (10-25kg)',
    id: 505,
    label: $localize`Epagneul Breton`,
  },
  {
    value: 'Epagneul de Pont-Audemer (10-25kg)',
    id: 506,
    label: $localize`Epagneul de Pont-Audemer`,
  },
  {
    value: 'Epagneul Francais (10-25kg)',
    id: 507,
    label: $localize`Epagneul Francais`,
  },
  {
    value: 'Epagneul Nain Continental (0-10kg)',
    id: 508,
    label: $localize`Epagneul Nain Continental`,
  },
  {
    value: 'Epagneul Picard (10-25kg)',
    id: 509,
    label: $localize`Epagneul Picard`,
  },
  {
    value: 'Epagneul Saint Usuge (10-25kg)',
    id: 510,
    label: $localize`Epagneul Saint Usuge`,
  },
  {
    value: 'Erdelyi Kopo (25-40kg)',
    id: 511,
    label: $localize`Erdelyi Kopo`,
  },
  {
    value: 'Eurasier (10-25kg)',
    id: 512,
    label: $localize`Eurasier`,
  },
  {
    value: 'European Sled Dog (ESD) (10-25kg)',
    id: 513,
    label: $localize`European Sled Dog (ESD)`,
  },
  {
    value: 'Field Spaniel (10-25kg)',
    id: 514,
    label: $localize`Field Spaniel`,
  },
  {
    value: 'Fila Brasileiro (>40kg)',
    id: 515,
    label: $localize`Fila Brasileiro`,
  },
  {
    value: 'Flat Coated Retriever (25-40kg)',
    id: 516,
    label: $localize`Flat Coated Retriever`,
  },
  {
    value: 'Fox Terrier (0-10kg)',
    id: 517,
    label: $localize`Fox Terrier`,
  },
  {
    value: 'Français blanc et noir / Französischer weiss-schwarzer Laufhund (10-25kg)',
    id: 518,
    label: $localize`Français blanc et noir / Französischer weiss-schwarzer Laufhund`,
  },
  {
    value: 'Français blanc et orange / Französischer weiss-oranger Laufhund (10-25kg)',
    id: 519,
    label: $localize`Français blanc et orange / Französischer weiss-oranger Laufhund`,
  },
  {
    value: 'Français tricolore / Französischer dreifarbiger Laufhund (10-25kg)',
    id: 520,
    label: $localize`Français tricolore / Französischer dreifarbiger Laufhund`,
  },
  {
    value: 'Französische Bulldogge / Bouledogue Français (10-25kg)',
    id: 521,
    label: $localize`Französische Bulldogge / Bouledogue Français`,
  },
  {
    value: 'Gammel Dansk Honsehund / Altdänischer Vorstehhund (25-40kg)',
    id: 522,
    label: $localize`Gammel Dansk Honsehund / Altdänischer Vorstehhund`,
  },
  {
    value: 'Germanischer Bärenhund (>40kg)',
    id: 523,
    label: $localize`Germanischer Bärenhund`,
  },
  {
    value: 'Goldendoodle (10-25kg)',
    id: 791,
    label: $localize`Goldendoodle`,
  },
  {
    value: 'Golden Retriever (25-40kg)',
    id: 524,
    label: $localize`Golden Retriever`,
  },
  {
    value: 'Gonczy Polski / Polnischer Jagdhund / Chien Courant Polonais (10-25kg)',
    id: 525,
    label: $localize`Gonczy Polski / Polnischer Jagdhund / Chien Courant Polonais`,
  },
  {
    value: 'Gordon Setter (10-25kg)',
    id: 526,
    label: $localize`Gordon Setter`,
  },
  {
    value: "Gos d'Atura Catala / Perro de Pastor Catalan (10-25kg)",
    id: 527,
    label: $localize`Gos d'Atura Catala / Perro de Pastor Catalan`,
  },
  {
    value: 'Grand Anglo-Francais Blanc & Orange (25-40kg)',
    id: 528,
    label: $localize`Grand Anglo-Francais Blanc & Orange`,
  },
  {
    value: 'Grand Anglo-Francais Blanc Et Noir (25-40kg)',
    id: 529,
    label: $localize`Grand Anglo-Francais Blanc Et Noir`,
  },
  {
    value: 'Grand Anglo-Francais Tricolore (25-40kg)',
    id: 530,
    label: $localize`Grand Anglo-Francais Tricolore`,
  },
  {
    value: 'Grand Basset Griffon Vendee (0-10kg)',
    id: 531,
    label: $localize`Grand Basset Griffon Vendee`,
  },
  {
    value: 'Grand Bleu de Gascogne (25-40kg)',
    id: 532,
    label: $localize`Grand Bleu de Gascogne`,
  },
  {
    value: 'Grand Griffon Vendeen (10-25kg)',
    id: 533,
    label: $localize`Grand Griffon Vendeen`,
  },
  {
    value: 'Greyhound (25-40kg)',
    id: 534,
    label: $localize`Greyhound`,
  },
  {
    value: 'Greyster (10-25kg)',
    id: 535,
    label: $localize`Greyster`,
  },
  {
    value: 'Griechischer Schäferhund / Hellenikos Poimenikos  (10-25kg)',
    id: 536,
    label: $localize`Griechischer Schäferhund / Hellenikos Poimenikos`,
  },
  {
    value: 'Griffon Belge / Belgischer Griffon / Griffon Belge (0-10kg)',
    id: 537,
    label: $localize`Griffon Belge / Belgischer Griffon / Griffon Belge`,
  },
  {
    value: 'Griffon Bleu de Gascogne (10-25kg)',
    id: 538,
    label: $localize`Griffon Bleu de Gascogne`,
  },
  {
    value: 'Griffon Bruxellois (0-10kg)',
    id: 539,
    label: $localize`Griffon Bruxellois`,
  },
  {
    value: 'Griffon d’Arret a poil dur Korthals (10-25kg)',
    id: 540,
    label: $localize`Griffon d’Arret a poil dur Korthals`,
  },
  {
    value: 'Griffon Fauve de Bretagne (10-25kg)',
    id: 541,
    label: $localize`Griffon Fauve de Bretagne`,
  },
  {
    value: 'Griffon Nivernais (10-25kg)',
    id: 542,
    label: $localize`Griffon Nivernais`,
  },
  {
    value: 'Grönlandhund / Chien du Groenland / Qimmeq Qimuttoq (25-40kg)',
    id: 543,
    label: $localize`Grönlandhund / Chien du Groenland / Qimmeq Qimuttoq`,
  },
  {
    value: 'Grosser Münsterländer / Grand Epagneul de Münster (25-40kg)',
    id: 544,
    label: $localize`Grosser Münsterländer / Grand Epagneul de Münster`,
  },
  {
    value: 'Hahoawu (Schensi) (0-10kg)',
    id: 545,
    label: $localize`Hahoawu (Schensi)`,
  },
  {
    value: 'Haldenstøver (10-25kg)',
    id: 546,
    label: $localize`Haldenstøver`,
  },
  {
    value: 'Hamiltonstovare (10-25kg)',
    id: 547,
    label: $localize`Hamiltonstovare`,
  },
  {
    value: 'Hannoverscher Schweisshund / Chien de Recherche au Sang de Hanovre (25-40kg)',
    id: 548,
    label: $localize`Hannoverscher Schweisshund / Chien de Recherche au Sang de Hanovre`,
  },
  {
    value: 'Harrier (10-25kg)',
    id: 549,
    label: $localize`Harrier`,
  },
  {
    value: 'Harzer-Fuchs (10-25kg)',
    id: 550,
    label: $localize`Harzer-Fuchs`,
  },
  {
    value: 'Havaneser / Bichon Havanais (0-10kg)',
    id: 551,
    label: $localize`Havaneser / Bichon Havanais`,
  },
  {
    value: 'Hellinikos Ichnilatis / Griechischer Laufhund / Chien Courant Grec (10-25kg)',
    id: 552,
    label: $localize`Hellinikos Ichnilatis / Griechischer Laufhund / Chien Courant Grec`,
  },
  {
    value: 'Hokkaido (10-25kg)',
    id: 553,
    label: $localize`Hokkaido`,
  },
  {
    value: 'Hollandse Herdershond / Holländischer Schäferhund / Berger Hollandais (25-40kg)',
    id: 554,
    label: $localize`Hollandse Herdershond / Holländischer Schäferhund / Berger Hollandais`,
  },
  {
    value: 'Hollandse Smoushond / Holländischer Smoushund / Smous des Pays-Bas (0-10kg)',
    id: 555,
    label: $localize`Hollandse Smoushond / Holländischer Smoushund / Smous des Pays-Bas`,
  },
  {
    value: 'Hovawart (25-40kg)',
    id: 556,
    label: $localize`Hovawart`,
  },
  {
    value: 'Hrvatski Ovcar / Kroatischer Schäferhund / Chien Berger Croate (10-25kg)',
    id: 557,
    label: $localize`Hrvatski Ovcar / Kroatischer Schäferhund / Chien Berger Croate`,
  },
  {
    value: 'Huntaway (25-40kg)',
    id: 558,
    label: $localize`Huntaway`,
  },
  {
    value: 'Hygenhund (10-25kg)',
    id: 559,
    label: $localize`Hygenhund`,
  },
  {
    value: 'Irish Glen of Imaal Terrier (10-25kg)',
    id: 560,
    label: $localize`Irish Glen of Imaal Terrier`,
  },
  {
    value: 'Irish Red and White Setter (25-40kg)',
    id: 561,
    label: $localize`Irish Red and White Setter`,
  },
  {
    value: 'Irish Soft Coated Wheaten Terrier (10-25kg)',
    id: 562,
    label: $localize`Irish Soft Coated Wheaten Terrier`,
  },
  {
    value: 'Irish Terrier (10-25kg)',
    id: 563,
    label: $localize`Irish Terrier`,
  },
  {
    value: 'Irish Water Spaniel (10-25kg)',
    id: 564,
    label: $localize`Irish Water Spaniel`,
  },
  {
    value: 'Irish Wolfhound / Irischer Wolfshund / Levrier Irlandais (>40kg)',
    id: 565,
    label: $localize`Irish Wolfhound / Irischer Wolfshund / Levrier Irlandais`,
  },
  {
    value: 'Islenskur Fjarhundur / Islandhund / Chien de Berger Islandais (10-25kg)',
    id: 566,
    label: $localize`Islenskur Fjarhundur / Islandhund / Chien de Berger Islandais`,
  },
  {
    value: 'Istarski Kratkodlaki Gonic (10-25kg)',
    id: 567,
    label: $localize`Istarski Kratkodlaki Gonic`,
  },
  {
    value: 'Istarski Ostrodlaki Gonic (10-25kg)',
    id: 568,
    label: $localize`Istarski Ostrodlaki Gonic`,
  },
  {
    value: 'Jack Russell Terrier (0-10kg)',
    id: 569,
    label: $localize`Jack Russell Terrier`,
  },
  {
    value: 'Jagdhund, klein / Chien de Chasse, petit (0-10kg)',
    id: 571,
    label: $localize`Jagdhund, klein / Chien de Chasse, petit (bis 10kg)`,
  },
  {
    value: 'Jagdhund, mittel / Chien de Chasse, moyen (10-25kg)',
    id: 572,
    label: $localize`Jagdhund, mittel / Chien de Chasse, moyen (bis 25kg)`,
  },
  {
    value: 'Jagdhund, gross / Chien de Chasse, grand (25-40kg)',
    id: 570,
    label: $localize`Jagdhund, gross / Chien de Chasse, grand (bis 40kg)`,
  },
  {
    value: 'Jagdterrier / Terrier de Chasse (0-10kg)',
    id: 573,
    label: $localize`Jagdterrier / Terrier de Chasse`,
  },
  {
    value: 'Jämthund (10-25kg)',
    id: 574,
    label: $localize`Jämthund`,
  },
  {
    value: 'Jura Laufhund / Ratier du Jura (0-10kg)',
    id: 575,
    label: $localize`Jura Laufhund / Ratier du Jura`,
  },
  {
    value: 'Kai (10-25kg)',
    id: 576,
    label: $localize`Kai`,
  },
  {
    value: 'Kangal Coban Kopegi (>40kg)',
    id: 577,
    label: $localize`Kangal Coban Kopegi`,
  },
  {
    value: 'Karakatschan / Bulgarischer Hirtenhund  (>40kg)',
    id: 578,
    label: $localize`Karakatschan / Bulgarischer Hirtenhund`,
  },
  {
    value: 'Karjalankarhukoira / Karelischer Bärenhund (10-25kg)',
    id: 579,
    label: $localize`Karjalankarhukoira / Karelischer Bärenhund`,
  },
  {
    value: 'Kavkazskaia Ovtcharka / Kaukasischer Owtscharka (>40kg)',
    id: 580,
    label: $localize`Kavkazskaia Ovtcharka / Kaukasischer Owtscharka`,
  },
  {
    value: 'Kerry Blue Terrier (10-25kg)',
    id: 581,
    label: $localize`Kerry Blue Terrier`,
  },
  {
    value: 'Khalag Tazi / Afghanischer Wüstenhund (25-40kg)',
    id: 582,
    label: $localize`Khalag Tazi / Afghanischer Wüstenhund`,
  },
  {
    value: 'King Charles Spaniel (0-10kg)',
    id: 583,
    label: $localize`King Charles Spaniel`,
  },
  {
    value: 'Kishu (10-25kg)',
    id: 584,
    label: $localize`Kishu`,
  },
  {
    value: 'Kleiner Münsterländer / Petit Epagneul de Münster (10-25kg)',
    id: 585,
    label: $localize`Kleiner Münsterländer / Petit Epagneul de Münster`,
  },
  {
    value: 'Komondor (>40kg)',
    id: 586,
    label: $localize`Komondor`,
  },
  {
    value: 'Korea Jindo Dog / Koreanischer Jindo / Jindo Coreen (10-25kg)',
    id: 587,
    label: $localize`Korea Jindo Dog / Koreanischer Jindo / Jindo Coreen`,
  },
  {
    value: 'Kraski Ovcar (25-40kg)',
    id: 588,
    label: $localize`Kraski Ovcar`,
  },
  {
    value: 'Kritikos Lagonikos (10-25kg)',
    id: 589,
    label: $localize`Kritikos Lagonikos`,
  },
  {
    value: 'Kromfohrländer (10-25kg)',
    id: 590,
    label: $localize`Kromfohrländer`,
  },
  {
    value: 'Kurzhaariger Pyrenäenschäferhund / Chien de Berger des Pyrenees a face rase (10-25kg)',
    id: 591,
    label: $localize`Kurzhaariger Pyrenäenschäferhund / Chien de Berger des Pyrenees a face rase`,
  },
  {
    value: 'Kuvasz (10-25kg)',
    id: 592,
    label: $localize`Kuvasz`,
  },
  {
    value: 'Labrador / Retriever du Labrador (10-25kg)',
    id: 593,
    label: $localize`Labrador / Retriever du Labrador`,
  },
  {
    value: 'Labradoodle (10-25kg)',
    id: 790,
    label: $localize`Labradoodle`,
  },
  {
    value: 'Lagotto Romagnolo (10-25kg)',
    id: 594,
    label: $localize`Lagotto Romagnolo`,
  },
  {
    value: 'Lakeland Terrier (0-10kg)',
    id: 595,
    label: $localize`Lakeland Terrier`,
  },
  {
    value: 'Lancashire Heeler (0-10kg)',
    id: 596,
    label: $localize`Lancashire Heeler`,
  },
  {
    value: 'Landseer (>40kg)',
    id: 597,
    label: $localize`Landseer`,
  },
  {
    value: 'Langhaariger Pyrenäenschäferhund / Chien de Berger des Pyrenees a poil long (10-25kg)',
    id: 598,
    label: $localize`Langhaariger Pyrenäenschäferhund / Chien de Berger des Pyrenees a poil long`,
  },
  {
    value: 'Lapinporokoira (25-40kg)',
    id: 599,
    label: $localize`Lapinporokoira`,
  },
  {
    value: 'Leonberger (>40kg)',
    id: 600,
    label: $localize`Leonberger`,
  },
  {
    value: 'Lhasa Apso (0-10kg)',
    id: 601,
    label: $localize`Lhasa Apso`,
  },
  {
    value: 'Longhaired Whippet (10-25kg)',
    id: 602,
    label: $localize`Longhaired Whippet`,
  },
  {
    value: 'Louisiana Catahoula Leopard Dog (25-40kg)',
    id: 603,
    label: $localize`Louisiana Catahoula Leopard Dog`,
  },
  {
    value: 'Magyar Agar (10-25kg)',
    id: 604,
    label: $localize`Magyar Agar`,
  },
  {
    value: 'Magyar Vizsla Drotzoru (10-25kg)',
    id: 605,
    label: $localize`Magyar Vizsla Drotzoru`,
  },
  {
    value: 'Magyar Vizsla Rovidszoru (10-25kg)',
    id: 606,
    label: $localize`Magyar Vizsla Rovidszoru`,
  },
  {
    value: 'Maltese (0-10kg)',
    id: 607,
    label: $localize`Maltese`,
  },
  {
    value: 'Manchester Terrier (0-10kg)',
    id: 608,
    label: $localize`Manchester Terrier`,
  },
  {
    value: 'Markiesje (10-25kg)',
    id: 609,
    label: $localize`Markiesje`,
  },
  {
    value: 'Mastiff (>40kg)',
    id: 610,
    label: $localize`Mastiff`,
  },
  {
    value: 'Mastin del Pirineo (>40kg)',
    id: 611,
    label: $localize`Mastin del Pirineo`,
  },
  {
    value: 'Mastin Espanol (>40kg)',
    id: 612,
    label: $localize`Mastin Espanol`,
  },
  {
    value: 'Mastino Neapoletano (>40kg)',
    id: 613,
    label: $localize`Mastino Neapoletano`,
  },
  {
    value: 'Miniature American Shepherd (10-25kg)',
    id: 614,
    label: $localize`Miniature American Shepherd`,
  },
  {
    value: 'Miniature Bull Terrier (10-25kg)',
    id: 615,
    label: $localize`Miniature Bull Terrier`,
  },
  {
    value: 'Molosser, mitttelgross / Molosse, moyen (10-25kg)',
    id: 621,
    label: $localize`Molosser, mitttelgross / Molosse, moyen (bis 25kg)`,
  },
  {
    value: 'Molosser, gross / Molosse, grand (25-40kg)',
    id: 620,
    label: $localize`Molosser, gross / Molosse, grand (bis 40kg)`,
  },
  {
    value: 'Molosser, riesig / Molosse, énorme (>40kg)',
    id: 622,
    label: $localize`Molosser, riesig / Molosse, énorme (bis 41kg)`,
  },
  {
    value: 'Mops / Carlin / Pug (0-10kg)',
    id: 623,
    label: $localize`Mops / Carlin / Pug`,
  },
  {
    value: 'Mudi (10-25kg)',
    id: 624,
    label: $localize`Mudi`,
  },
  {
    value: 'Nederlandse Kooikerhondje (0-10kg)',
    id: 625,
    label: $localize`Nederlandse Kooikerhondje`,
  },
  {
    value: 'Nederlandse Schapendoes (10-25kg)',
    id: 626,
    label: $localize`Nederlandse Schapendoes`,
  },
  {
    value: 'Neufundländer / Newfoundland / Chien de Terre-Neuve (>40kg)',
    id: 627,
    label: $localize`Neufundländer / Newfoundland / Chien de Terre-Neuve`,
  },
  {
    value: 'Nihon Supittsu (0-10kg)',
    id: 628,
    label: $localize`Nihon Supittsu`,
  },
  {
    value: 'Nihon Teria (0-10kg)',
    id: 629,
    label: $localize`Nihon Teria`,
  },
  {
    value: 'Norrbottenspets (10-25kg)',
    id: 630,
    label: $localize`Norrbottenspets`,
  },
  {
    value: 'Norsk Buhund / Norwegischer Buhund / Buhund Norvegien (10-25kg)',
    id: 631,
    label: $localize`Norsk Buhund / Norwegischer Buhund / Buhund Norvegien`,
  },
  {
    value: 'Norsk Elghund Gra (10-25kg)',
    id: 632,
    label: $localize`Norsk Elghund Gra`,
  },
  {
    value: 'Norsk Elghund Sort (10-25kg)',
    id: 633,
    label: $localize`Norsk Elghund Sort`,
  },
  {
    value: 'Norsk Lundehund (0-10kg)',
    id: 634,
    label: $localize`Norsk Lundehund`,
  },
  {
    value: 'North American Shepherd (10-25kg)',
    id: 635,
    label: $localize`North American Shepherd`,
  },
  {
    value: 'Norwich Terrier (0-10kg)',
    id: 636,
    label: $localize`Norwich Terrier`,
  },
  {
    value: 'Nova Scotia Duck Tolling Retriever (10-25kg)',
    id: 637,
    label: $localize`Nova Scotia Duck Tolling Retriever`,
  },
  {
    value: 'Ogar Polski (25-40kg)',
    id: 638,
    label: $localize`Ogar Polski`,
  },
  {
    value: 'Old English Bulldog (10-25kg)',
    id: 639,
    label: $localize`Old English Bulldog`,
  },
  {
    value: 'Old English Sheepdog / Bobtail (25-40kg)',
    id: 640,
    label: $localize`Old English Sheepdog / Bobtail`,
  },
  {
    value: 'Österreichischer Pinscher / Pinscher Autrichien (10-25kg)',
    id: 641,
    label: $localize`Österreichischer Pinscher / Pinscher Autrichien`,
  },
  {
    value: 'Otterhound / Otterhund / Chien a Loutre (25-40kg)',
    id: 642,
    label: $localize`Otterhound / Otterhund / Chien a Loutre`,
  },
  {
    value: 'Pachon Navarro (25-40kg)',
    id: 643,
    label: $localize`Pachon Navarro`,
  },
  {
    value: 'Pariahund (10-25kg)',
    id: 644,
    label: $localize`Pariahund`,
  },
  {
    value: 'Parson Russell Terrier (0-10kg)',
    id: 645,
    label: $localize`Parson Russell Terrier`,
  },
  {
    value: 'Patterdale Terrier (10-25kg)',
    id: 646,
    label: $localize`Patterdale Terrier`,
  },
  {
    value: 'Pekingese (0-10kg)',
    id: 647,
    label: $localize`Pekingese`,
  },
  {
    value: 'Perdigueiro Portugues (10-25kg)',
    id: 648,
    label: $localize`Perdigueiro Portugues`,
  },
  {
    value: 'Perdiguero de Burgos (25-40kg)',
    id: 649,
    label: $localize`Perdiguero de Burgos`,
  },
  {
    value: 'Perro de Agua Espanol (10-25kg)',
    id: 650,
    label: $localize`Perro de Agua Espanol`,
  },
  {
    value: 'Perro Dogo Mallorquin / Ca de Bou (25-40kg)',
    id: 651,
    label: $localize`Perro Dogo Mallorquin / Ca de Bou`,
  },
  {
    value: 'Perro Sin Pelo Del Peru (10-25kg)',
    id: 652,
    label: $localize`Perro Sin Pelo Del Peru`,
  },
  {
    value: 'Petit Basset Griffon Vendeen (0-10kg)',
    id: 653,
    label: $localize`Petit Basset Griffon Vendeen`,
  },
  {
    value: 'Petit Bleu de Gascogne (10-25kg)',
    id: 654,
    label: $localize`Petit Bleu de Gascogne`,
  },
  {
    value: 'Petit Brabancon (0-10kg)',
    id: 655,
    label: $localize`Petit Brabancon`,
  },
  {
    value: 'Petit Chien Lion / Löwchen (0-10kg)',
    id: 656,
    label: $localize`Petit Chien Lion / Löwchen`,
  },
  {
    value: 'Petit gascon saintongeois (25-40kg)',
    id: 657,
    label: $localize`Petit gascon saintongeois`,
  },
  {
    value: 'Pharaoh Hound (10-25kg)',
    id: 658,
    label: $localize`Pharaoh Hound`,
  },
  {
    value: 'Piccolo Levriero Italiano (0-10kg)',
    id: 659,
    label: $localize`Piccolo Levriero Italiano`,
  },
  {
    value: 'Pit Bull Terrier (10-25kg)',
    id: 660,
    label: $localize`Pit Bull Terrier`,
  },
  {
    value: 'Plott Hound (10-25kg)',
    id: 661,
    label: $localize`Plott Hound`,
  },
  {
    value: 'Podenco Andaluz (10-25kg)',
    id: 662,
    label: $localize`Podenco Andaluz`,
  },
  {
    value: 'Podenco Canario (10-25kg)',
    id: 663,
    label: $localize`Podenco Canario`,
  },
  {
    value: 'Podenco Ibicenco (10-25kg)',
    id: 664,
    label: $localize`Podenco Ibicenco`,
  },
  {
    value: 'Podengo Portugues Pequeno (10-25kg)',
    id: 665,
    label: $localize`Podengo Portugues Pequeno`,
  },
  {
    value: 'Poitevin (10-25kg)',
    id: 666,
    label: $localize`Poitevin`,
  },
  {
    value:
      'Polski Owczarek Nizinny / Polnischer Niederungshütehund / Berger Polonais de Plaine (0-10kg)',
    id: 667,
    label: $localize`Polski Owczarek Nizinny / Polnischer Niederungshütehund / Berger Polonais de Plaine`,
  },
  {
    value: 'Polski Owczarek Podhalanski / Tatra-Schäferhund / Chien de Berger des Tatras (10-25kg)',
    id: 668,
    label: $localize`Polski Owczarek Podhalanski / Tatra-Schäferhund / Chien de Berger des Tatras`,
  },
  {
    value: 'Porcelaine (10-25kg)',
    id: 669,
    label: $localize`Porcelaine`,
  },
  {
    value: 'Posavski Gonic (10-25kg)',
    id: 670,
    label: $localize`Posavski Gonic`,
  },
  {
    value: 'Prager Rattler (Prazsky Krysarik) (0-10kg)',
    id: 671,
    label: $localize`Prager Rattler (Prazsky Krysarik)`,
  },
  {
    value: 'Pudel, klein / Caniche, petit (0-10kg)',
    id: 673,
    label: $localize`Pudel, klein / Caniche, petit (bis 10kg)`,
  },
  {
    value: 'Pudel, mittelgross / Caniche, moyen (10-25kg)',
    id: 674,
    label: $localize`Pudel, mittelgross / Caniche, moyen (bis 25kg)`,
  },
  {
    value: 'Pudel, gross / Caniche, grand (25-40kg)',
    id: 672,
    label: $localize`Pudel, gross / Caniche, grand (bis 40kg)`,
  },
  {
    value: 'Pudelpointer (10-25kg)',
    id: 675,
    label: $localize`Pudelpointer`,
  },
  {
    value: 'Puli (10-25kg)',
    id: 676,
    label: $localize`Puli`,
  },
  {
    value: 'Pumi (10-25kg)',
    id: 677,
    label: $localize`Pumi`,
  },
  {
    value: 'Pyrenäenberghund / Patou / Chien de Montagne des Pyrenees (>40kg)',
    id: 678,
    label: $localize`Pyrenäenberghund / Patou / Chien de Montagne des Pyrenees`,
  },
  {
    value: 'Rafeiro do Alentejo (>40kg)',
    id: 679,
    label: $localize`Rafeiro do Alentejo`,
  },
  {
    value: 'Rat Terrier (0-10kg)',
    id: 680,
    label: $localize`Rat Terrier`,
  },
  {
    value: 'Ratonero Bodeguero Andaluz (0-10kg)',
    id: 681,
    label: $localize`Ratonero Bodeguero Andaluz`,
  },
  {
    value: 'Rhodesian Ridgeback (25-40kg)',
    id: 682,
    label: $localize`Rhodesian Ridgeback`,
  },
  {
    value: 'Riesenschnauzer / Schnauzer Geant (25-40kg)',
    id: 683,
    label: $localize`Riesenschnauzer / Schnauzer Geant`,
  },
  {
    value: 'Rottweiler (>40kg)',
    id: 684,
    label: $localize`Rottweiler`,
  },
  {
    value: 'Russkiy Tchiorny Terrier / Russischer schwarzer Terrier / Terrier Noir Russe (25-40kg)',
    id: 685,
    label: $localize`Russkiy Tchiorny Terrier / Russischer schwarzer Terrier / Terrier Noir Russe`,
  },
  {
    value: 'Russkiy Toy / Russischer Toy Terrier / Petit Chien Russe (0-10kg)',
    id: 686,
    label: $localize`Russkiy Toy / Russischer Toy Terrier / Petit Chien Russe`,
  },
  {
    value: 'Russko-Evropeiskaia Laika (10-25kg)',
    id: 687,
    label: $localize`Russko-Evropeiskaia Laika`,
  },
  {
    value: 'Saarlooswolfhond (25-40kg)',
    id: 688,
    label: $localize`Saarlooswolfhond`,
  },
  {
    value: 'Sabueso Espanol (10-25kg)',
    id: 689,
    label: $localize`Sabueso Espanol`,
  },
  {
    value: 'Saluki (10-25kg)',
    id: 690,
    label: $localize`Saluki`,
  },
  {
    value: 'Samoiedskaia Sabaka / Samojede / Samoyede (10-25kg)',
    id: 691,
    label: $localize`Samoiedskaia Sabaka / Samojede / Samoyede`,
  },
  {
    value: 'Sarplaninac (Jugoslovenski Ovcarski Pas) (>40kg)',
    id: 692,
    label: $localize`Sarplaninac (Jugoslovenski Ovcarski Pas)`,
  },
  {
    value: 'Schäferhund, mittelgross / Chien de Berger, moyen (10-25kg)',
    id: 694,
    label: $localize`Schäferhund, mittelgross / Chien de Berger, moyen (bis 25kg)`,
  },
  {
    value: 'Schäferhund, gross / Chien de Berger, grand (25-40kg)',
    id: 693,
    label: $localize`Schäferhund, gross / Chien de Berger, grand (bis 40kg)`,
  },
  {
    value: 'Schäferhund, riesig / Chien de Berger, énorme (>40kg)',
    id: 695,
    label: $localize`Schäferhund, riesig / Chien de Berger, énorme (ab 41kg)`,
  },
  {
    value: 'Schillerstövare (10-25kg)',
    id: 696,
    label: $localize`Schillerstövare`,
  },
  {
    value: 'Schipperke (0-10kg)',
    id: 697,
    label: $localize`Schipperke`,
  },
  {
    value: 'Schnauzer, klein / Schnauzer, petit (0-10kg)',
    id: 699,
    label: $localize`Schnauzer, klein / Schnauzer, petit (bis 10kg)`,
  },
  {
    value: 'Schnauzer, mittelgross / Schnauzer, moyen (10-25kg)',
    id: 700,
    label: $localize`Schnauzer, mittelgross / Schnauzer, moyen (bis 25kg)`,
  },
  {
    value: 'Schnauzer, gross / Schnauzer, grand (25-40kg)',
    id: 698,
    label: $localize`Schnauzer, gross / Schnauzer, grand (bis 40kg)`,
  },
  {
    value: 'Schnauzer, riesig / Schnauzer, énorme (>40kg)',
    id: 701,
    label: $localize`Schnauzer, riesig / Schnauzer, énorme (ab 41kg)`,
  },
  {
    value: 'Schwarzwalder Schweisshund (10-25kg)',
    id: 702,
    label: $localize`Schwarzwalder Schweisshund`,
  },
  {
    value: 'Schweizer Laufhund / Chien Courant Suisse (10-25kg)',
    id: 703,
    label: $localize`Schweizer Laufhund / Chien Courant Suisse`,
  },
  {
    value: 'Schweizer Niederlaufhund / Petit Chien Courant Suisse (0-10kg)',
    id: 704,
    label: $localize`Schweizer Niederlaufhund / Petit Chien Courant Suisse`,
  },
  {
    value: 'Schweizer Sennenhund, mittelgross / Bouvier Suisse, moyen (10-25kg)',
    id: 706,
    label: $localize`Schweizer Sennenhund, mittelgross / Bouvier Suisse, moyen (bis 25kg)`,
  },
  {
    value: 'Schweizer Sennenhund, gross / Bouvier Suisse, grand (25-40kg)',
    id: 705,
    label: $localize`Schweizer Sennenhund, gross / Bouvier Suisse, grand (bis 40kg)`,
  },
  {
    value: 'Schweizer Sennenhund, riesig / Bouvier Suisse, énorme (>40kg)',
    id: 707,
    label: $localize`Schweizer Sennenhund, riesig / Bouvier Suisse, énorme (ab 41kg)`,
  },
  {
    value: 'Scottish Terrier (0-10kg)',
    id: 708,
    label: $localize`Scottish Terrier`,
  },
  {
    value: 'Sealyham Terrier (0-10kg)',
    id: 709,
    label: $localize`Sealyham Terrier`,
  },
  {
    value: 'Segugio Italiano a pelo forte (10-25kg)',
    id: 710,
    label: $localize`Segugio Italiano a pelo forte`,
  },
  {
    value: 'Segugio Italiano a pelo raso (10-25kg)',
    id: 711,
    label: $localize`Segugio Italiano a pelo raso`,
  },
  {
    value: 'Segugio Maremmano (10-25kg)',
    id: 712,
    label: $localize`Segugio Maremmano`,
  },
  {
    value: 'Setter (25-40kg)',
    id: 713,
    label: $localize`Setter`,
  },
  {
    value: 'Shar Pei (10-25kg)',
    id: 714,
    label: $localize`Shar Pei`,
  },
  {
    value: 'Shetland Sheepdog (Sheltie) (10-25kg)',
    id: 715,
    label: $localize`Shetland Sheepdog (Sheltie)`,
  },
  {
    value: 'Shiba Inu (0-10kg)',
    id: 716,
    label: $localize`Shiba Inu`,
  },
  {
    value: 'Shih Tzu (0-10kg)',
    id: 717,
    label: $localize`Shih Tzu`,
  },
  {
    value: 'Shikoku (10-25kg)',
    id: 718,
    label: $localize`Shikoku`,
  },
  {
    value: 'Shiloh Shepherd Dog (>40kg)',
    id: 719,
    label: $localize`Shiloh Shepherd Dog`,
  },
  {
    value: 'Siberian Husky (10-25kg)',
    id: 720,
    label: $localize`Siberian Husky`,
  },
  {
    value: 'Silken Windsprite (10-25kg)',
    id: 792,
    label: $localize`Silken Windsprite`,
  },
  {
    value: 'Skye Terrier (0-10kg)',
    id: 721,
    label: $localize`Skye Terrier`,
  },
  {
    value: 'Sloughi (10-25kg)',
    id: 722,
    label: $localize`Sloughi`,
  },
  {
    value: 'Slovensky Cuvac / Slowakischer Tschuvatsch / Tchouvatch Slovaque (25-40kg)',
    id: 723,
    label: $localize`Slovensky Cuvac / Slowakischer Tschuvatsch / Tchouvatch Slovaque`,
  },
  {
    value:
      'Slovensky Hrubosrsty Stavac (Ohar) / Slowakischer Rauhbart / Braque Slovaque a Poil Dur (10-25kg)',
    id: 724,
    label: $localize`Slovensky Hrubosrsty Stavac (Ohar) / Slowakischer Rauhbart / Braque Slovaque a Poil Dur`,
  },
  {
    value: 'Slovensky Kopov / Slowakischer Laufhund / Chien Courant Slovaque (10-25kg)',
    id: 725,
    label: $localize`Slovensky Kopov / Slowakischer Laufhund / Chien Courant Slovaque`,
  },
  {
    value: 'Smalandstövare (10-25kg)',
    id: 726,
    label: $localize`Smalandstövare`,
  },
  {
    value: 'Spanischer Windhund / Galgo Espanol (10-25kg)',
    id: 727,
    label: $localize`Spanischer Windhund / Galgo Espanol`,
  },
  {
    value: 'Spinone Italiano (25-40kg)',
    id: 728,
    label: $localize`Spinone Italiano`,
  },
  {
    value: 'Sredneasiatskaia Ovtcharka (>40kg)',
    id: 729,
    label: $localize`Sredneasiatskaia Ovtcharka`,
  },
  {
    value: 'Srpski Gonic / Serbischer Laufhund / Chien Courant Serbe (10-25kg)',
    id: 730,
    label: $localize`Srpski Gonic / Serbischer Laufhund / Chien Courant Serbe`,
  },
  {
    value:
      'Srpski Trobojni Gonic / Dreifarbiger Serbischer Laufhund / Chien Courant Tricolore Serbe (10-25kg)',
    id: 731,
    label: $localize`Srpski Trobojni Gonic / Dreifarbiger Serbischer Laufhund / Chien Courant Tricolore Serbe`,
  },
  {
    value: "Stabijhoun / Friesischer Vorstehhund / Chien d'Arret Frison (10-25kg)",
    id: 732,
    label: $localize`Stabijhoun / Friesischer Vorstehhund / Chien d'Arret Frison`,
  },
  {
    value: 'Staffordshire Bull Terrier (10-25kg)',
    id: 733,
    label: $localize`Staffordshire Bull Terrier`,
  },
  {
    value: 'Steirische Rauhhaarbracke (10-25kg)',
    id: 734,
    label: $localize`Steirische Rauhhaarbracke`,
  },
  {
    value: 'Suomenajokoira / Finnischer Laufhund / Chien Courant Finlandais (10-25kg)',
    id: 735,
    label: $localize`Suomenajokoira / Finnischer Laufhund / Chien Courant Finlandais`,
  },
  {
    value: 'Suomenlapinkoira / Finnischer Lapphund / Chien Finnois de Laponie (10-25kg)',
    id: 736,
    label: $localize`Suomenlapinkoira / Finnischer Lapphund / Chien Finnois de Laponie`,
  },
  {
    value: 'Suomenpystykorva / Finnen-Spitz / Spitz Finlandais (10-25kg)',
    id: 737,
    label: $localize`Suomenpystykorva / Finnen-Spitz / Spitz Finlandais`,
  },
  {
    value: 'Sussex Spaniel (10-25kg)',
    id: 738,
    label: $localize`Sussex Spaniel`,
  },
  {
    value: 'Svensk Lapphund / Schwedischer Lapphund / Lapphund Suedois (10-25kg)',
    id: 739,
    label: $localize`Svensk Lapphund / Schwedischer Lapphund / Lapphund Suedois`,
  },
  {
    value: 'Taiwan Dog (10-25kg)',
    id: 740,
    label: $localize`Taiwan Dog`,
  },
  {
    value: 'Terrier Brasileiro (0-10kg)',
    id: 741,
    label: $localize`Terrier Brasileiro`,
  },
  {
    value: 'Terrier, klein / Terrier, petit (0-10kg)',
    id: 743,
    label: $localize`Terrier, klein / Terrier, petit (bis 10kg)`,
  },
  {
    value: 'Terrier, mittel / Terrier, moyen (10-25kg)',
    id: 744,
    label: $localize`Terrier, mittel / Terrier, moyen (bis 25kg)`,
  },
  {
    value: 'Terrier, gross / Terrier, grand (25-40kg)',
    id: 742,
    label: $localize`Terrier, gross / Terrier, grand (bis 40kg)`,
  },
  {
    value: 'Thai Bangkaew Dog (10-25kg)',
    id: 745,
    label: $localize`Thai Bangkaew Dog`,
  },
  {
    value: 'Thai Ridgeback Dog (10-25kg)',
    id: 746,
    label: $localize`Thai Ridgeback Dog`,
  },
  {
    value: 'Tibetan Spaniel (0-10kg)',
    id: 747,
    label: $localize`Tibetan Spaniel`,
  },
  {
    value: 'Tibetan Terrier (10-25kg)',
    id: 748,
    label: $localize`Tibetan Terrier`,
  },
  {
    value: 'Tiroler Bracke (0-10kg)',
    id: 749,
    label: $localize`Tiroler Bracke`,
  },
  {
    value: 'Tornjak (25-40kg)',
    id: 750,
    label: $localize`Tornjak`,
  },
  {
    value: 'Tosa (>40kg)',
    id: 751,
    label: $localize`Tosa`,
  },
  {
    value: 'Treeing Walker Coonhound (10-25kg)',
    id: 752,
    label: $localize`Treeing Walker Coonhound`,
  },
  {
    value: 'Treibhund, klein / Bouvier des Flandres, petit (0-10kg)',
    id: 754,
    label: $localize`Treibhund, klein / Bouvier des Flandres, petit (bis 10kg)`,
  },
  {
    value: 'Treibhund, mittelgross / Bouvier des Flandres, moyen (10-25kg)',
    id: 755,
    label: $localize`Treibhund, mittelgross / Bouvier des Flandres, moyen (bis 25kg)`,
  },
  {
    value: 'Treibhund, gross / Bouvier des Flandres, grand (25-40kg)',
    id: 753,
    label: $localize`Treibhund, gross / Bouvier des Flandres, grand (bis 40kg)`,
  },
  {
    value: 'Unbestimmt / Indéfini (10-25kg)',
    id: 756,
    label: $localize`Unbestimmt / Indéfini`,
  },
  {
    value: 'Västgötaspets (10-25kg)',
    id: 757,
    label: $localize`Västgötaspets`,
  },
  {
    value: 'Volpino Italiano (0-10kg)',
    id: 758,
    label: $localize`Volpino Italiano`,
  },
  {
    value: 'Vostotchno-Sibirskaia Laika (10-25kg)',
    id: 759,
    label: $localize`Vostotchno-Sibirskaia Laika`,
  },
  {
    value: 'Wäller (25-40kg)',
    id: 760,
    label: $localize`Wäller`,
  },
  {
    value: 'Weimaraner / Braque de Weimar (25-40kg)',
    id: 761,
    label: $localize`Weimaraner / Braque de Weimar`,
  },
  {
    value: 'Weisser Schweizer Schäferhund / Berger Blanc Suisse (25-40kg)',
    id: 762,
    label: $localize`Weisser Schweizer Schäferhund / Berger Blanc Suisse`,
  },
  {
    value: 'Welsh Corgi Cardigan (0-10kg)',
    id: 763,
    label: $localize`Welsh Corgi Cardigan`,
  },
  {
    value: 'Welsh Corgi Pembroke (0-10kg)',
    id: 764,
    label: $localize`Welsh Corgi Pembroke`,
  },
  {
    value: 'Welsh Springer Spaniel (10-25kg)',
    id: 765,
    label: $localize`Welsh Springer Spaniel`,
  },
  {
    value: 'Welsh Terrier (0-10kg)',
    id: 766,
    label: $localize`Welsh Terrier`,
  },
  {
    value: 'West Highland White Terrier (0-10kg)',
    id: 767,
    label: $localize`West Highland White Terrier`,
  },
  {
    value: 'Westfalenterrier (10-25kg)',
    id: 768,
    label: $localize`Westfalenterrier`,
  },
  {
    value: 'Westfalische Dachsbracke (0-10kg)',
    id: 769,
    label: $localize`Westfalische Dachsbracke`,
  },
  {
    value: 'Wetterhoun (25-40kg)',
    id: 770,
    label: $localize`Wetterhoun`,
  },
  {
    value: 'Whippet (10-25kg)',
    id: 771,
    label: $localize`Whippet`,
  },
  {
    value: 'Xoloitzcuintle / Mexikanischer Nackthund (10-25kg)',
    id: 772,
    label: $localize`Xoloitzcuintle / Mexikanischer Nackthund`,
  },
  {
    value: 'Yakutskaya Laika (10-25kg)',
    id: 773,
    label: $localize`Yakutskaya Laika`,
  },
  {
    value: 'Yorkshire Terrier (0-10kg)',
    id: 774,
    label: $localize`Yorkshire Terrier`,
  },
  {
    value:
      'Yuzhnorusskaya Ovcharka / Südrussischer Ovtcharka / Berger de Russie Meridionale (25-40kg)',
    id: 775,
    label: $localize`Yuzhnorusskaya Ovcharka / Südrussischer Ovtcharka / Berger de Russie Meridionale`,
  },
  {
    value: 'Zapadno-Sibirskaia Laika (10-25kg)',
    id: 776,
    label: $localize`Zapadno-Sibirskaia Laika`,
  },
  {
    value: 'Zwergpinscher / Pinscher Nain (0-10kg)',
    id: 777,
    label: $localize`Zwergpinscher / Pinscher Nain`,
  },
  {
    value: 'Zwergschnauzer / Schnauzer Nain (0-10kg)',
    id: 778,
    label: $localize`Zwergschnauzer / Schnauzer Nain`,
  },
  {
    value: 'Zwergspitz / Pomeranian (0-10kg)',
    id: 779,
    label: $localize`Zwergspitz / Pomeranian`,
  },
];
