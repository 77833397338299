import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';

import { SelectElementMapper } from '../../_services/types/commons.type';
import { ContractToSignInfo } from '../../_services/types/contract-template.types';
import { ContractType } from '../../enums/contract-type.enum';
import { InsuranceTypeEnum } from '../../enums/insurance-type.enum';
import {
  getHouseholdAdditionalCoverLabel,
  HouseholdAdditionalCovers as HouseholdAdditionalCoverEnum,
} from '../../enums/rc-household/household-additional-covers.enum';
import {
  getHouseholdCoversLabel,
  HouseholdCovers as HouseholdCoverEnum,
} from '../../enums/rc-household/household-covers.enum';
import { BanditIconComponent } from '../../ui-kit/icons/bandit-icon.component';
import { BrokenGlassIconComponent } from '../../ui-kit/icons/broken-glass-icon.component';
import { CarIconComponent } from '../../ui-kit/icons/car-icon.component';
import { CartwheelIconComponent } from '../../ui-kit/icons/cartwheel-icon.component';
import { EarthquakeIconComponent } from '../../ui-kit/icons/earthquake-icon.component';
import { FireIconComponent } from '../../ui-kit/icons/fire-icon.component';
import { LightningIconComponent } from '../../ui-kit/icons/lightning-icon.component';
import { PipeIconComponent } from '../../ui-kit/icons/pipe-icon.component';
import { SmileyIconComponent } from '../../ui-kit/icons/smiley-icon.component';
import { ThiefIconComponent } from '../../ui-kit/icons/thief-icon.component';

export const HOUSEHOLD_INSURANCE_BASE_URL = 'rc-menage';

export const HOUSEHOLD_INSURANCE_FORM_BASE_URL = 'formulaire';

export const HOUSEHOLD_INSURANCE_OFFER_BASE_URL = 'offre';

export const HOUSEHOLD_STEPS_BASE = `${HOUSEHOLD_INSURANCE_BASE_URL}/${HOUSEHOLD_INSURANCE_FORM_BASE_URL}`;

export const HOUSEHOLD_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_RC Menage',
  product_category: 'RC Menage',
  product_name: 'Market',
  funnel_complete: false,
};

export const HOUSEHOLD_STEPS: {
  STATUS: Step;
  POLICYHOLDER_COUNT: Step;
  BIRTHDATE: Step;
  DOG: Step;
  PLACE: Step;
  ROOMS: Step;
  START_DATE: Step;
  COVERS: Step;
  CIVIL_LIABILITY: Step;
  AMOUNT_TO_COVER: Step;
  DEDUCTIBLE: Step;
  ADDITIONAL_COVERS: Step;
  OFFER_SEARCH: Step;
} = {
  STATUS: {
    step: [1, 1],
    url: 'status',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Statut',
    },
  },
  POLICYHOLDER_COUNT: {
    step: [1, 1],
    url: 'nombre-assure',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Nombre assurés',
    },
  },
  BIRTHDATE: {
    step: [1, 1],
    url: 'date-naissance',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Date de naissance',
    },
  },
  DOG: {
    step: [1, 1],
    url: 'chien',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Chien',
    },
  },
  PLACE: {
    step: [2, 1],
    url: 'lieu',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Lieu',
    },
  },
  ROOMS: {
    step: [2, 1],
    url: 'pieces',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Pièces',
    },
  },
  START_DATE: {
    step: [3, 1],
    url: 'date-debut',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Date de début',
    },
  },
  COVERS: {
    step: [3, 1],
    url: 'couvertures',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Couvertures',
    },
  },
  CIVIL_LIABILITY: {
    step: [4, 1],
    url: 'responsabilite-civile',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Responsabilité civile',
    },
  },
  AMOUNT_TO_COVER: {
    step: [4, 1],
    url: 'montant',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Montant',
    },
  },
  DEDUCTIBLE: {
    step: [4, 1],
    url: 'franchise',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Franchise',
    },
  },
  ADDITIONAL_COVERS: {
    step: [4, 1],
    url: 'couvertures-complementaires',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Couvertures supplémentaires',
    },
  },
  OFFER_SEARCH: {
    step: [5, 1],
    url: 'offres',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Offres',
    },
  },
};

export const HOUSEHOLD_OFFER_FORM_BASE = `${HOUSEHOLD_INSURANCE_BASE_URL}/${HOUSEHOLD_INSURANCE_OFFER_BASE_URL}`;

export const HOUSEHOLD_OFFER_FORM_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  QUESTIONNAIRE: Step;
  SUMMARY: Step;
  CONTRACT_SIGNATURE: Step;
  CANCELLATION: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 14,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 2],
    url: 'connexion',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 2],
    url: 'inscription',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 16,
      stage_name: 'Informations personnelles',
    },
  },
  QUESTIONNAIRE: {
    step: [3, 1],
    url: 'questionnaire',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 17,
      stage_name: 'Questionnaire',
    },
  },
  SUMMARY: {
    step: [4, 1],
    url: 'recapitulatif',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 18,
      stage_name: 'Récapitulatif',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [5, 1],
    url: 'signature-contrat',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 19,
      stage_name: 'Signatures',
    },
  },
  CANCELLATION: {
    step: [5, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...HOUSEHOLD_FUNNEL_EVENT_BASE,
      funnel_stage: 20,
      stage_name: '(Option) Résiliation',
    },
  },
};

export const HOUSELHOLD_COVERS: SelectElementMapper<HouseholdCoverEnum> = {
  [HouseholdCoverEnum.NATURAL_EVENTS]: {
    label: getHouseholdCoversLabel(HouseholdCoverEnum.NATURAL_EVENTS),
    value: HouseholdCoverEnum.NATURAL_EVENTS,
    iconComponent: FireIconComponent,
    info: $localize`Elle couvre les 10 éléments suivants : incendie, chutes de pierre, hautes eaux, avalanche, pluie, inondations, tempêtes, éboulement, grêle, glissement de terrain`,
  },
  [HouseholdCoverEnum.ROBBERY]: {
    label: getHouseholdCoversLabel(HouseholdCoverEnum.ROBBERY),
    value: HouseholdCoverEnum.ROBBERY,
    iconComponent: ThiefIconComponent,
    info: $localize`Cela englobe 3 genres de vol : vol simple, détroussement, vol par effraction`,
  },
  [HouseholdCoverEnum.OUTSIDE_ROBBERY]: {
    label: getHouseholdCoversLabel(HouseholdCoverEnum.OUTSIDE_ROBBERY),
    value: HouseholdCoverEnum.OUTSIDE_ROBBERY,
    iconComponent: BanditIconComponent,
    info: $localize`Cela englobe les objets du ménage volés en dehors du lieu de risque, par exemple un sac de sport`,
  },
  [HouseholdCoverEnum.HEARTHQUAKE]: {
    label: getHouseholdCoversLabel(HouseholdCoverEnum.HEARTHQUAKE),
    value: HouseholdCoverEnum.HEARTHQUAKE,
    iconComponent: EarthquakeIconComponent,
    info: $localize`Cela englobe les biens matériels à l'intérieur du ménage en cas de tremblement de terre`,
  },
  [HouseholdCoverEnum.WATER_DAMAGE]: {
    label: getHouseholdCoversLabel(HouseholdCoverEnum.WATER_DAMAGE),
    value: HouseholdCoverEnum.WATER_DAMAGE,
    iconComponent: PipeIconComponent,
    info: $localize`Englobe tout ce qui concerne les conduites d'eau du mobilier (par exemple la conduite de la machine à laver)`,
  },
  [HouseholdCoverEnum.GLASS_BREAKAGE]: {
    label: getHouseholdCoversLabel(HouseholdCoverEnum.GLASS_BREAKAGE),
    value: HouseholdCoverEnum.GLASS_BREAKAGE,
    iconComponent: BrokenGlassIconComponent,
    info: $localize`Englobe tout ce qui est vitrage du mobilier (par exemple une armoire vitrée)`,
  },
  [HouseholdCoverEnum.OUTDOOR_EQUIPMENT]: {
    label: getHouseholdCoversLabel(HouseholdCoverEnum.OUTDOOR_EQUIPMENT),
    value: HouseholdCoverEnum.OUTDOOR_EQUIPMENT,
    iconComponent: CartwheelIconComponent,
    info: $localize`Englobe tous les aménagements extérieurs décoratifs et/ou mobiles`,
  },
  [HouseholdCoverEnum.ELECTRONIC_DEVICES]: {
    label: getHouseholdCoversLabel(HouseholdCoverEnum.ELECTRONIC_DEVICES),
    value: HouseholdCoverEnum.ELECTRONIC_DEVICES,
    iconComponent: LightningIconComponent,
    info: $localize`Cela englobe les choses qui nécessitent de l'énergie électrique (par exemple ton ordinateur, ton smartphone,ta télévision ou ta cave à vin frigorifique)`,
  },
};

export const HOUSEHOLD_ADDITIONAL_COVERS: SelectElementMapper<HouseholdAdditionalCoverEnum> = {
  [HouseholdAdditionalCoverEnum.VEHICLE]: {
    label: getHouseholdAdditionalCoverLabel(HouseholdAdditionalCoverEnum.VEHICLE),
    value: HouseholdAdditionalCoverEnum.VEHICLE,
    iconComponent: CarIconComponent,
    info: $localize`Cela couvre le véhicule que tu conduis occasionnellement et qui ne t'appartient pas.`,
  },
  [HouseholdAdditionalCoverEnum.GROSS_NEGLIGENCE]: {
    label: getHouseholdAdditionalCoverLabel(HouseholdAdditionalCoverEnum.GROSS_NEGLIGENCE),
    value: HouseholdAdditionalCoverEnum.GROSS_NEGLIGENCE,
    iconComponent: SmileyIconComponent,
    info: $localize`Cela te protège si tu as malencontreusement enfreint une règle et que cela a provoqué un dommage. L'assureur renonce donc a te poursuivre en justice.`,
  },
  [HouseholdAdditionalCoverEnum.HORSE]: {
    label: getHouseholdAdditionalCoverLabel(HouseholdAdditionalCoverEnum.HORSE),
    value: HouseholdAdditionalCoverEnum.HORSE,
    iconComponent: BanditIconComponent,
    info: $localize`Cela englobe les frais vétérinaires engendrés par un accident provoqué par toi-même en pratiquant des sports hippiques.`,
  },
  [HouseholdAdditionalCoverEnum.HUNTER]: {
    label: getHouseholdAdditionalCoverLabel(HouseholdAdditionalCoverEnum.HUNTER),
    value: HouseholdAdditionalCoverEnum.HUNTER,
    iconComponent: PipeIconComponent,
    info: $localize`Cela englobe les frais engendrés par un dommage corporel ou matériel provoqué par toi-même en pratiquant des sports de chasse.`,
  },
};

export const HOUSEHOLD_END_URL = `${HOUSEHOLD_INSURANCE_BASE_URL}/fin`;

export const HOUSEHOLD_INSURANCE_END_EVENT: FunnelDatalayerEvent = {
  ...HOUSEHOLD_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 21,
  stage_name: 'Fin',
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 RC ménage',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.RC_HOUSEHOLD,
  },
  {
    name: 'Mandat gestion RC ménage',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.RC_HOUSEHOLD,
  },
];
