import { SelectElementMapper } from 'src/app/_services/types/commons.type';
import { ContractToSignInfo } from 'src/app/_services/types/contract-template.types';
import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';
import { ContractType } from 'src/app/enums/contract-type.enum';
import { InsuranceTypeEnum } from 'src/app/enums/insurance-type.enum';
import { SexEnum } from 'src/app/enums/sex.enum';
import {
  getOwnDescriptionEnum,
  getSavingReasonEnumLabel,
  getThirdPillarSituationLabel,
  OwnDescriptionEnum,
  SavingReasonEnum,
  ThirdPillarSituationEnum,
} from 'src/app/enums/third_pillar.enum';
import { SelectElement } from 'src/app/ui-kit/components/select/select.component';
import { CheckIconComponent } from 'src/app/ui-kit/icons/check-icon.component';
import { CrossIconComponent } from 'src/app/ui-kit/icons/cross-icon.component';
import { FemaleIconComponent } from 'src/app/ui-kit/icons/female-icon.component';
import { MaleIconComponent } from 'src/app/ui-kit/icons/male-icon.component';

export const THIRD_PILLAR_INSURANCE_BASE = 'troisieme-pilier';
export const THIRD_PILLAR_INSURANCE_INPUT = 'formulaire';
export const THIRD_PILLAR_INSURANCE_OFFER = 'offre';
export const THIRD_PILLAR_INSURANCE_END = 'fin';
export const THIRD_PILLAR_INSURANCE_END_URL = `${THIRD_PILLAR_INSURANCE_BASE}/fin`;

export const THIRD_PILLAR_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Troisieme pilier',
  product_category: 'Troisieme pilier',
  product_name: 'Market',
  funnel_complete: false,
};

export const THIRD_PILLAR_INSURANCE_END_EVENT: FunnelDatalayerEvent = {
  ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 16,
  stage_name: 'Fin',
};

export const THIRD_PILLAR_INPUT_FORM_BASE = `${THIRD_PILLAR_INSURANCE_BASE}/${THIRD_PILLAR_INSURANCE_INPUT}/`;

export const THIRD_PILLAR_INPUT_FORM_STEPS: {
  NPA: Step;
  SEX: Step;
  BIRTHDATE: Step;
  SITUATION: Step;
  LPP: Step;
  SAVING: Step;
  SAVING_REASONS: Step;
  OWN_DESCRIPTION: Step;
  RECHERCHE_OFFRES: Step;
} = {
  NPA: {
    step: [1, 1],
    url: 'npa',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'NPA',
    },
  },
  SEX: {
    step: [1, 1],
    url: 'genre',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Genre',
    },
  },
  BIRTHDATE: {
    step: [1, 1],
    url: 'date-naissance',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Date de naissance',
    },
  },
  SITUATION: {
    step: [1, 1],
    url: 'situation',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Situation',
    },
  },
  LPP: {
    step: [1, 1],
    url: 'lpp',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'LLP',
    },
  },
  SAVING: {
    step: [1, 1],
    url: 'épargne',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Epargne',
    },
  },
  SAVING_REASONS: {
    step: [1, 1],
    url: 'raisons-épargne',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: "Raison d'épargne",
    },
  },
  OWN_DESCRIPTION: {
    step: [2, 1],
    url: 'description-caractere',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Description du caractère',
    },
  },
  RECHERCHE_OFFRES: {
    step: [3, 1],
    url: 'offres',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Offres',
    },
  },
};

export const THIRD_PILLAR_OFFER_FORM_BASE = `${THIRD_PILLAR_INSURANCE_BASE}/${THIRD_PILLAR_INSURANCE_OFFER}/`;

export const THIRD_PILLAR_OFFER_FORM_STEPS: {
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  CONTRACT_SIGNATURE: Step;
  REQUEST_SENDER: Step;
  CANCELLATION: Step;
} = {
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 1],
    url: 'connexion',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 1],
    url: 'inscription',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: 'Informations personnelles',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [3, 1],
    url: 'signature-contrat',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Signatures',
    },
  },
  REQUEST_SENDER: {
    step: [3, 1],
    url: 'envoi-demande',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 14,
      stage_name: 'Envoi demande',
    },
  },
  CANCELLATION: {
    step: [3, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...THIRD_PILLAR_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: '(Option) Résiliation',
    },
  },
};

export const THIRD_PILLAR_BOOLEAN_OPTIONS: SelectElement<boolean>[] = [
  {
    value: true,
    label: $localize`Oui`,
    iconComponent: CheckIconComponent,
  },
  {
    value: false,
    label: $localize`Non`,
    iconComponent: CrossIconComponent,
  },
];

export const THIRD_PILLAR_SEX_SELECT: SelectElementMapper<SexEnum> = {
  [SexEnum.MALE]: {
    value: SexEnum.MALE,
    label: $localize`Homme`,
    iconComponent: MaleIconComponent,
  },
  [SexEnum.FEMALE]: {
    value: SexEnum.FEMALE,
    label: $localize`Femme`,
    iconComponent: FemaleIconComponent,
  },
};

export const THIRD_PILLAR_SITUATIONS_SELECT: SelectElementMapper<ThirdPillarSituationEnum> = {
  [ThirdPillarSituationEnum.EMPLOYEE]: {
    value: ThirdPillarSituationEnum.EMPLOYEE,
    label: getThirdPillarSituationLabel(ThirdPillarSituationEnum.EMPLOYEE),
  },
  [ThirdPillarSituationEnum.INDEPENDENT]: {
    value: ThirdPillarSituationEnum.INDEPENDENT,
    label: getThirdPillarSituationLabel(ThirdPillarSituationEnum.INDEPENDENT),
  },
  [ThirdPillarSituationEnum.OTHER]: {
    value: ThirdPillarSituationEnum.OTHER,
    label: getThirdPillarSituationLabel(ThirdPillarSituationEnum.OTHER),
  },
};

export const THIRD_PILLAR_SAVING_REASONS: SelectElementMapper<SavingReasonEnum> = {
  [SavingReasonEnum.TAX_SAVING]: {
    value: SavingReasonEnum.TAX_SAVING,
    label: getSavingReasonEnumLabel(SavingReasonEnum.TAX_SAVING),
  },
  [SavingReasonEnum.RETIREMENT_CALCULATION]: {
    value: SavingReasonEnum.RETIREMENT_CALCULATION,
    label: getSavingReasonEnumLabel(SavingReasonEnum.RETIREMENT_CALCULATION),
  },
  [SavingReasonEnum.PROPERTY_PURCHASE]: {
    value: SavingReasonEnum.PROPERTY_PURCHASE,
    label: getSavingReasonEnumLabel(SavingReasonEnum.PROPERTY_PURCHASE),
  },
  [SavingReasonEnum.FAMILY_PROTECTION]: {
    value: SavingReasonEnum.FAMILY_PROTECTION,
    label: getSavingReasonEnumLabel(SavingReasonEnum.FAMILY_PROTECTION),
  },
  [SavingReasonEnum.OTHER]: {
    value: SavingReasonEnum.OTHER,
    label: getSavingReasonEnumLabel(SavingReasonEnum.OTHER),
  },
};

export const THIRD_PILLAR_OWN_DESCRIPTION_SELECT: SelectElementMapper<OwnDescriptionEnum> = {
  [OwnDescriptionEnum.CAUTIOUS]: {
    value: OwnDescriptionEnum.CAUTIOUS,
    label: getOwnDescriptionEnum(OwnDescriptionEnum.CAUTIOUS),
  },
  [OwnDescriptionEnum.MODERATE]: {
    value: OwnDescriptionEnum.MODERATE,
    label: getOwnDescriptionEnum(OwnDescriptionEnum.MODERATE),
  },
  [OwnDescriptionEnum.GAMBLER]: {
    value: OwnDescriptionEnum.GAMBLER,
    label: getOwnDescriptionEnum(OwnDescriptionEnum.GAMBLER),
  },
  [OwnDescriptionEnum.SPECULATOR]: {
    value: OwnDescriptionEnum.SPECULATOR,
    label: getOwnDescriptionEnum(OwnDescriptionEnum.SPECULATOR),
  },
  [OwnDescriptionEnum.SOCIALLY_RESPONSIBLE]: {
    value: OwnDescriptionEnum.SOCIALLY_RESPONSIBLE,
    label: getOwnDescriptionEnum(OwnDescriptionEnum.SOCIALLY_RESPONSIBLE),
  },
};

export const THIRD_PILLAR_OWN_DESCRIPTION_RECOMMENDED = OwnDescriptionEnum.MODERATE;

const computeMonthlySavingOptions = (
  beginValue: number,
  endValue: number,
  step: number,
  visibleLabelStep: number,
) => {
  const lastValueFullStep = Math.floor((endValue - beginValue) / step) * step + beginValue;

  const values: number[] = [];
  const labels: string[] = [];
  for (let i = beginValue; i <= lastValueFullStep; i += step) {
    values.push(i);
    labels.push(i % visibleLabelStep === 0 ? i.toString() : '');
  }

  if (lastValueFullStep !== endValue) {
    values.push(endValue);
    labels.push(endValue.toString());
  }
  return {
    values,
    labels,
  };
};

export const THIRD_PILLAR_MONTHLY_SAVING_DEFAULT: { values: number[]; labels: string[] } =
  computeMonthlySavingOptions(0, 588, 50, 100);

export const THIRD_PILLAR_MONTHLY_SAVING_INDEPENDENT: { values: number[]; labels: string[] } =
  computeMonthlySavingOptions(0, 2940, 100, 500);

export const THIRD_PILLAR_OFFERS_ADVANTAGES = [
  {
    title: $localize`Moins d'impôts`,
    description: $localize`Quand tu verses de l'argent dans ton 3ème pilier, tu peux déduire ces sommes de tes impôts. Cela veut dire que tu paieras moins d'impôts chaque année.`,
  },
  {
    title: $localize`Épargne pour plus tard`,
    description: $localize`Le 3ème pilier te permet de mettre de l'argent de côté pour ta retraite.`,
  },
  {
    title: $localize`Sécurité financière`,
    description: $localize`En épargnant avec un 3ème pilier, tu te assures d'avoir de l'argent pour ta retraite ou en cas d'urgence, comme un accident ou une maladie grave.`,
  },
  {
    title: $localize`Choix d'investissement`,
    description: $localize`Les contrats de 3ème pilier offrent différents choix d'investissement, pour que tu puisses choisir ceux qui correspondent le mieux à tes objectifs financiers et à ta tolérance au risque.`,
  },
  {
    title: $localize`Protection pour toi et ta famille`,
    description: $localize`Certains contrats de 3ème pilier incluent une assurance décès ou invalidité, ce qui signifie que tes bénéficiaires recevront de l'argent si quelque chose t'arrive.`,
  },
];

export const THIRD_PILLAR_MALE_RETIREMENT_AGE = 65;
export const THIRD_PILLAR_FEMALE_RETIREMENT_AGE = 64;

export const THIRD_PILLAR_CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 troisième pilier',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.THIRD_PILLAR,
  },
  {
    name: 'Mandat gestion troisième pilier',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.THIRD_PILLAR,
  },
];
